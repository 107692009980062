<script>
// The Fibonacci tool for drawing Fibonacci retracement levels on a chart

import Overlay from "../../mixins/overlay.js";
import Tool from "../../mixins/tool.js";
import Icons from "../../stuff/icons.json";
import Pin from "../primitives/pin.js";
import Seg from "../primitives/seg.js";

export default {
  name: "TrendBasedFib", // Name of the component
  mixins: [Overlay, Tool], // Mixin dependencies

  methods: {
    // Meta information about the tool
    meta_info() {
      return { author: "chandan-atiuttam", version: "2.0.1" };
    },

    // Tool descriptor
    tool() {
      return {
        group: "Fibonacci",
        icon: Icons["trend-base-fib.svg"],
        type: "Retracement",
        hint: "Trend Retracement",
        data: [],
        settings: {
          lineColor: "#000000",
          backgroundColors: [
            "#F5051D80",
            "#F5051D80",
            "#F0D32080",
            "#75F04A80",
            "#3EF07A80",
            "#4239F080",
            "#F5D98F80",
            "#A44DF580",
            "#F573B780",
            "#E794F580",
          ],
        },
        mods: {
          ShiftMode: {
            settings: {
              price: false,
              time: true,
              shiftMode: true,
            },
            hidden: true,
          },
        },
      };
    },

    // Called after the overlay is mounted
    init() {
      // Add pin p1 at initial position
      this.pins.push(
        new Pin(this, "p1", {
          hidden: this.shift,
        })
      );

      // Add pin p2 that follows the mouse until clicked
      this.pins.push(
        new Pin(this, "p2", {
          state: "tracking",
          hidden: this.shift,
        })
      );

      // Add pin p3 initially hidden
      this.pins.push(
        new Pin(this, "p3", {
          state: "tracking",
          hidden: this.shift,
        })
      );

      // When p2 settles, set p3's position to match p2
      this.pins[1].on("settled", () => {
        this.pins[2].on("tracking", () => {
          console.log("hello");
          this.set_state("settled");
        });

        this.set_state("nodragging");
        if (this.shift) {
          this._$emit("custom-event", {
            event: "object-selected",
            args: [],
          });
        }
      });

      // When p3 settles, finalize the drawing
      this.pins[2].on("settled", () => {
        this.set_state("finished");
        this.$emit("drawing-mode-off");
      });
    },

    // Draw method for rendering the Fibonacci retracement levels
    draw(ctx) {
      if (!this.p1 || !this.p2 || !this.p3) return;

      ctx.lineWidth = this.line_width;
      ctx.strokeStyle = this.line_color;
      ctx.beginPath();

      // Draw the line segment between p1 and p2
      new Seg(this, ctx).draw(this.p1, this.p3);
      // Draw the line segment between p2 and p3
      new Seg(this, ctx).draw(this.p3, this.p2);
      // Render pins
      ctx.stroke();

      const layout = this.$props.layout;
      const xm = layout.t2screen((this.p2[0] + this.p3[0]) * 0.5);

      ctx.lineWidth = this.line_width;
      ctx.strokeStyle = this.line_color;

      let x1 = layout.t2screen(this.p2[0]);
      let y1 = layout.$2screen(this.p2[1]);
      let x2 = layout.t2screen(this.p3[0]);
      let y2 = layout.$2screen(this.p3[1]);

      let priceRange = Math.abs(this.p3[1] - this.p2[1]);
      let fibLevels = [
        0, 0.236, 0.382, 0.5, 0.618, 1, 1.618, 2.618, 3.618, 4.236,
      ];
      let fibPercentages = [
        "0.000%",
        "23.600%",
        "38.200%",
        "50.000%",
        "61.800%",
        "100.000%",
        "161.800%",
        "261.800%",
        "361.800%",
        "423.600%",
      ];
      let textColors = [
        "#C3B4B4",
        "#F71010",
        "#E3F952",
        "#3EDA4D",
        "#3EDA81",
        "#3390B7",
        "#978034",
        "#8D1DCE",
        "#E828EF",
        "#752777",
      ];

      let previousY = y1;
      fibLevels.forEach((level, index) => {
        // this will get the actual price from the price / side bar
        let fibPrice =
          this.p2[1] + level * priceRange * (this.p3[1] > this.p2[1] ? 1 : -1);
        let y = layout.$2screen(fibPrice);

        // Fill the background color between the previousY and the current y
        if (index < this.sett.backgroundColors.length) {
          this.horizontal(
            ctx,
            x1,
            previousY,
            x2,
            y,
            this.sett.backgroundColors[index]
          );
        }

        ctx.beginPath();
        ctx.moveTo(x1, y);
        ctx.lineTo(x2, y);
        ctx.stroke();

        // Draw the price value at the current Fibonacci level
        this.drawPriceValues(
          ctx,
          x1,
          y,
          fibPrice,
          fibPercentages[index],
          textColors[index]
        );

        previousY = y;
      });

      this.render_pins(ctx);
    },
    horizontal(ctx, x1, yTop, x2, yBottom, backgroundColor) {
      const layout = this.$props.layout;
      const xdir = Math.sign(this.p3[0] - this.p2[0]);
      const ym =
        (layout.$2screen(this.p2[1]) + layout.$2screen(this.p3[1])) / 2;

      ctx.fillStyle = backgroundColor;
      ctx.fillRect(x1, yTop, x2 - x1, yBottom - yTop);

      ctx.beginPath();

      ctx.moveTo(x2 - 5 * xdir, ym - 4);
      ctx.lineTo(x2, ym);
      ctx.lineTo(x2 - 5 * xdir, ym + 4);
    },

    // this gives you the correct price values equally to the horizontal values
    drawPriceValues(ctx, x, y, price, percentage, textColor) {
      if (!this.p2 || !this.p3) return;

      // Example: Draw price at p1
      ctx.font = "12px Arial";
      ctx.fillStyle = textColor;
      ctx.textAlign = "left";
      ctx.fillText(`${percentage} ( ${price.toFixed(2)} )`, x + -70, y + -8);
    },

    // Define which tools this component should be used for
    use_for() {
      return ["TrendBasedFib"];
    },

    // Define the colors for the data
    data_colors() {
      return [this.color];
    },
  },

  // Define internal settings and constants
  computed: {
    sett() {
      return this.$props.settings;
    },
    p1() {
      return this.$props.settings.p1;
    },
    p2() {
      return this.$props.settings.p2;
    },
    p3() {
      return this.$props.settings.p3;
    },
    line_width() {
      return this.sett.lineWidth || 0.9;
    },
    line_color() {
      return this.sett.lineColor || "#000000"; // Default line color
    },
    color() {
      return this.sett.color || this.$props.colors.cross;
    },
    back_color() {
      return this.sett.backColor || "#9b9ba316";
    },
    value_back() {
      return this.sett.valueBack || "#9b9ba316";
    },
    value_color() {
      return this.sett.valueColor || this.$props.colors.text;
    },
    prec() {
      return this.sett.precision || 2;
    },
    new_font() {
      return "12px " + this.$props.font.split("px").pop();
    },
    price() {
      return "price" in this.sett ? this.sett.price : true;
    },
    time() {
      return "time" in this.sett ? this.sett.time : false;
    },
    shift() {
      return this.sett.shiftMode;
    },
  },

  data() {
    return {};
  },
};
</script>
