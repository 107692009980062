<template>
  <div :class="screenShotComponent">
    <h4 class="heading">CHART SNAPSHOT</h4>
    <div :class="buttonClass" @mouseover="hoveredButton = 'Copy Image'" @mouseleave="hoveredButton = ''"
      @click="getScreenShot">
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28">
        <g fill="none" fill-rule="evenodd" stroke="currentColor">
          <path d="M13.111 18.5H10.5a1 1 0 0 1-1-1v-11a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1h-8.389z"></path>
          <path d="M18.5 20v1.5a1 1 0 0 1-1 1h-11a1 1 0 0 1-1-1v-11a1 1 0 0 1 1-1H8"></path>
        </g>
      </svg>
      <p>Copy Image</p>
    </div>
    <div :class="buttonClass" @mouseover="hoveredButton = 'Download Image'" @mouseleave="hoveredButton = ''"
      @click="handleDownload">
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none">
        <path stroke="currentColor" d="M6.5 16v4.5a1 1 0 001 1h14a1 1 0 001-1V16M14.5 5V17m-4-3.5l4 4l4-4"></path>
      </svg>
      <p>Download Image</p>
    </div>
    <div :class="buttonClass" @mouseover="hoveredButton = 'Copy Link'" @mouseleave="hoveredButton = ''"
      @click="copyLink">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none">
        <path stroke="currentColor"
          d="M19 15l2.5-2.5c1-1 1.5-3.5-.5-5.5s-4.5-1.5-5.5-.5L13 9M10 12l-2.5 2.5c-1 1-1.5 3.5.5 5.5s4.5 1.5 5.5.5L16 18M17 11l-5 5">
        </path>
      </svg>
      <p>Copy Link</p>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import axios from "axios";
import { SERVER_URL } from '../constant.js'

export default {
  name: "ScreenshotComponent",
  data() {
    return {
      hoveredButton: "",
      capturedImage: null,
      isDark: JSON.parse(localStorage.getItem("themeIsDark")) || this.getTheme,
    };
  },
  props: ["tradingVueRef"],

  computed: {
    getTheme() {
      return this.$store.getters.getTheme;
    },
    screenShotComponent() {
      return this.isDark ? "component-dark" : "component";
    },
    buttonClass() {
      const className = this.isDark ? "button-dark" : "button";
      console.log("Button class:", className);
      return className;
    },
  },
  methods: {
    async getScreenShot() {
      await this.$bus.$emit("removeScreenShotComponent");

      if (this.tradingVueRef) {
        const canvas = await html2canvas(this.tradingVueRef);
        console.log(canvas);
        canvas.toBlob((blob) => {
          navigator.clipboard
            .write([
              new ClipboardItem({
                [blob.type]: blob,
              }),
            ])
            .then(() => {
              console.log("Image copied to clipboard");
            })
            .catch((error) => {
              console.error("Error copying image to clipboard:", error);
            });
        });
      } else {
        console.error("tradingVueRef is not set");
      }
    },
    async captureScreen() {
      await this.$bus.$emit("removeScreenShotComponent");
      if (this.tradingVueRef) {
        const canvas = await html2canvas(this.tradingVueRef);
        this.capturedImage = canvas.toDataURL("image/png");
        console.log(this.capturedImage);
        return this.capturedImage;
      } else {
        console.error("tradingVueRef is not set");
      }
    },
    async handleDownload() {
      if (this.capturedImage) {
        this.downloadImage();
      } else {
        await this.captureScreen();
        this.downloadImage();
      }
    },
    downloadImage() {
      const link = document.createElement("a");
      link.href = this.capturedImage;
      link.download = "captured-image.png";
      link.click();
      console.log("Image downloaded successfully");
    },
    async copyLink() {
      if (!this.capturedImage) {
        await this.captureScreen();
      }
      const url = `${SERVER_URL}/api/screenshot`;
      const data = {
        base64Data: this.capturedImage,
      };
      await axios
        .post(url, data)
        .then((res) => {
          navigator.clipboard.writeText(
            `${document.location.href}${res.data.data.data._id}`
          );
          this.$toast("Link Copied", {
            // For the actual toast, including different toast types:
            toastClassName: "my-custom-toast-class",

            // For the toast body when using strings or a custom component as content
            bodyClassName: ["custom-class-1", "custom-class-2"]
          });
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    },
  },
};
</script>

<style scoped>
.heading {
  margin-top: 4px;
  font-weight: 400;
  font-size: 11px;
  margin-left: 2px;
}

/* Light Mode Button Styles */
.button {
  display: flex;
  align-items: center;
  gap: 4px;
  text-align: center;
  width: 100%;
  margin-top: 5px;
  height: 30px;
  background-color: #f9f9f9;
  border: none;
  font-weight: 300;
  font-size: 14px;
  cursor: pointer;
}

.button:hover {
  background-color: #e0e0e0;
}

/* Dark Mode Button Styles */
.button-dark {
  background-color: #1e1e1e;
  color: white;
  display: flex;
  align-items: center;
  gap: 4px;
  text-align: center;
  width: 100%;
  margin-top: 5px;
  height: 30px;
  border: none;
  font-weight: 300;
  font-size: 14px;
  cursor: pointer;
}

.button-dark:hover {
  background-color: #333333;
  color: #ffffff;
  /* Optional: Change text color on hover */
}

.button svg {
  /* Add any specific styles for SVG icons here */
}

.component {
  width: 15%;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 4px;
  border-radius: 4px;
}

.component-dark {
  width: 15%;
  background-color: #1e1e1e;
  border: 1px solid #ccc;
  padding: 4px;
  border-radius: 4px;
}
</style>
