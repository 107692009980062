<template>
  <!-- Horizontal section: (grid + sidebar) -->
  <div class="trading-vue-section" @contextmenu.prevent="openContextMenu">
    <chart-legend
      ref="legend"
      :values="section_values"
      :grid_id="grid_id"
      :common="legend_props"
      :meta_props="get_meta_props"
      @legend-button-click="button_click"
    />
    <grid
      v-bind="grid_props"
      ref="grid"
      :select-candle="selectCandle"
      :grid_id="grid_id"
      @register-kb-listener="register_kb"
      @remove-kb-listener="remove_kb"
      @range-changed="range_changed"
      @cursor-changed="cursor_changed"
      @cursor-locked="cursor_locked"
      @layer-meta-props="emit_meta_props"
      @custom-event="emit_custom_event"
      @sidebar-transform="sidebar_transform"
      @rezoom-range="rezoom_range"
    />
    <ScreenshotComponent
      class="grid-options"
      v-if="showHoverComponent"
      :tradingVueRef="tradingVueRef"
    />

    <!-- <replay-controls /> -->
    <sidebar
      :ref="'sb-' + grid_id"
      v-bind="sidebar_props"
      :grid_id="grid_id"
      :rerender="rerender"
      @sidebar-transform="sidebar_transform"
    />
    <context-menu
      v-if="showContextMenu"
      :position="contextMenuPosition"
      @close="showContextMenu = false"
      @settings-click="openSettings"
    />
    <ChartSettings v-if="showCandlesModal" @close="showCandlesModal = false" />
  </div>
</template>

<script>
import Grid from "./Grid.vue";
import Sidebar from "./Sidebar.vue";
import ChartLegend from "./Legend.vue";
import Shaders from "../mixins/shaders.js";
import ReplayControls from "../ReplayControls.vue";
import ScreenshotComponent from "./ScreenshotComponent.vue";
import ContextMenu from "./overlays/CotextMenu.vue";
import ChartSettings from "./ChartSettings.vue";
export default {
  name: "GridSection",
  components: {
    Grid,
    Sidebar,
    ChartLegend,
    ReplayControls,
    ScreenshotComponent,
    ContextMenu,
    ChartSettings,
  },
  mixins: [Shaders],
  props: ["replay", "selectCandle", "common", "grid_id", "tradingVueRef"],
  data() {
    return {
      meta_props: {},
      rerender: 0,
      last_ghash: "",
      showHoverComponent: false,
      showContextMenu: false,
      showCandlesModal: false,
      contextMenuPosition: { top: "0px", left: "0px" },
    };
  },
  computed: {
    // Component-specific props subsets:
    grid_props() {
      const id = this.$props.grid_id;
      let p = Object.assign({}, this.$props.common);

      // Split offchart data between offchart grids
      if (id > 0) {
        let all = p.data;
        p.data = [p.data[id - 1]];
        // Merge offchart overlays with custom ids with
        // the existing onse (by comparing the grid ids)
        p.data.push(...all.filter((x) => x.grid && x.grid.id === id));
      }

      p.width = p.layout.grids[id].width;
      p.height = p.layout.grids[id].height;
      // console.log("height : ", p.height);
      p.y_transform = p.y_ts[id];
      p.shaders = this.grid_shaders;
      p.replay = this.replay;
      return p;
    },
    sidebar_props() {
      const id = this.$props.grid_id;
      let p = Object.assign({}, this.$props.common);
      p.width = p.layout.grids[id].sb;
      p.height = p.layout.grids[id].height;
      p.y_transform = p.y_ts[id];
      p.shaders = this.sb_shaders;
      p.replay = this.replay;
      return p;
    },
    section_values() {
      const id = this.$props.grid_id;
      let p = Object.assign({}, this.$props.common);
      p.width = p.layout.grids[id].width;
      return p.cursor.values[id];
    },
    legend_props() {
      const id = this.$props.grid_id;
      let p = Object.assign({}, this.$props.common);

      // Split offchart data between offchart grids
      if (id > 0) {
        let all = p.data;
        p.offchart = all;
        p.data = [p.data[id - 1]];
        p.data.push(...all.filter((x) => x.grid && x.grid.id === id));
      }
      return p;
    },
    get_meta_props() {
      return this.meta_props;
    },
    grid_shaders() {
      return this.shaders.filter((x) => x.target === "grid");
    },
    sb_shaders() {
      return this.shaders.filter((x) => x.target === "sidebar");
    },
  },
  watch: {
    common: {
      handler: function (val, old_val) {
        let newhash = this.ghash(val);
        if (newhash !== this.last_ghash) {
          this.rerender++;
        }

        if (val.data.length !== old_val.data.length) {
          // Look at this nasty trick!
          this.rerender++;
        }
        this.last_ghash = newhash;
      },
      deep: true,
    },
  },
  mounted() {
    this.init_shaders(this.$props.common.skin);
    this.handleShowHoverComponent();
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    openContextMenu(event) {
      this.contextMenuPosition = {
        top: `${event.clientY - 50}px`,
        left: `${event.clientX - 50}px`,
      };
      this.showContextMenu = true;
    },
    openSettings() {
      // Your code to open the settings modal or perform another action
      this.showContextMenu = false;
      this.showCandlesModal = true;
    },
    handleShowHoverComponent() {
      this.$bus.$on("showScreenShot", (showHoverComponent) => {
        this.showHoverComponent = showHoverComponent;
        console.log(showHoverComponent, this.showHoverComponent);
      });
    },
    handleClickOutside(event) {
      const contextMenuElement = this.$el.querySelector(".context-menu");
      if (contextMenuElement && !contextMenuElement.contains(event.target)) {
        this.showContextMenu = false;
      }
    },
    range_changed(r) {
      this.$emit("range-changed", r);
    },
    cursor_changed(c) {
      c.grid_id = this.$props.grid_id;
      this.$emit("cursor-changed", c);
    },
    cursor_locked(state) {
      this.$emit("cursor-locked", state);
    },
    sidebar_transform(s) {
      this.$emit("sidebar-transform", s);
    },
    emit_meta_props(d) {
      this.$set(this.meta_props, d.layer_id, d);
      this.$emit("layer-meta-props", d);
    },
    emit_custom_event(d) {
      this.on_shader_event(d, "sidebar");
      this.$emit("custom-event", d);
    },
    button_click(event) {
      this.$emit("legend-button-click", event);
    },
    register_kb(event) {
      this.$emit("register-kb-listener", event);
    },
    remove_kb(event) {
      this.$emit("remove-kb-listener", event);
    },
    rezoom_range(event) {
      let id = "sb-" + event.grid_id;
      if (this.$refs[id]) {
        this.$refs[id].renderer.rezoom_range(event.z, event.diff1, event.diff2);
      }
    },
    ghash(val) {
      // Measures grid heights configuration
      let hs = val.layout.grids.map((x) => x.height);
      return hs.reduce((a, b) => a + b, "");
    },
  },
};
</script>
<style>
.trading-vue-section {
  height: 0;
  position: absolute;
}
.grid-options {
  position: absolute;
  right: 10px; /* Adjust this value to fine-tune the horizontal position */
  z-index: 100; /* Ensure it's above other content */
  pointer-events: auto;
  transition: opacity 0.3s ease; /* Smooth transition animation */
}
</style>
