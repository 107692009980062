var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "copied-link-comp" }, [
    _c("div", { staticClass: "header" }, [
      _c("img", { staticClass: "logo", attrs: { src: _vm.logo, alt: "Logo" } }),
      _vm._v(" "),
      _c("div", { staticClass: "btn-comp" }, [
        _c(
          "button",
          { staticClass: "copy-link-btn", on: { click: _vm.copyLink } },
          [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 28 28",
                  width: "30",
                  height: "30",
                  fill: "none",
                },
              },
              [
                _c("path", {
                  attrs: {
                    stroke: "currentColor",
                    d: "M19 15l2.5-2.5c1-1 1.5-3.5-.5-5.5s-4.5-1.5-5.5-.5L13 9M10 12l-2.5 2.5c-1 1-1.5 3.5.5 5.5s4.5 1.5 5.5.5L16 18M17 11l-5 5",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c("p", [_vm._v("Copy Link")]),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "launch-trading-vue",
            on: { click: _vm.launchTradingVue },
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 28 28",
                  width: "30",
                  height: "30",
                },
              },
              [
                _c("path", {
                  attrs: {
                    fill: "currentColor",
                    d: "M24.72 9.3a1 1 0 0 1-.03 1.42l-7.3 7a1 1 0 0 1-1.43-.05l-4.71-5.23-6.56 6.28a1 1 0 1 1-1.38-1.44l7.3-7a1 1 0 0 1 1.43.05l4.71 5.23 6.56-6.28a1 1 0 0 1 1.41.03z",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c("p", [_vm._v("Launch Tradilent")]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "screenshot-div" }, [
      _vm.image
        ? _c("img", {
            staticClass: "screenshot",
            attrs: { src: _vm.image, alt: "Screenshot" },
          })
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }