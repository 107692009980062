<template>
    <div class="page" v-if="getIsSymbolSearchOpen">
        <div :class="mainClass">
            <!-- Close Button -->
            <button class="cross" @click="closeSymbolSearch">X</button>

            <!-- Title -->
            <h1>Symbol Search</h1>
            <hr>

            <!-- Search Bar -->
            <div class="search">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
                    <path stroke="currentColor" d="M12.4 12.5a7 7 0 1 0-4.9 2 7 7 0 0 0 4.9-2zm0 0l5.101 5"></path>
                </svg>
                <input ref="myInput" type="text" :class="inputClass" v-model="symbol" @input="updateSearch"
                    placeholder="Search Symbol">

                <!-- Tools Buttons -->
                <div class="tools">
                    <button :class="toolClass" @click="() => mathSymbol('/')">
                        <!-- divide -->
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" width="13" height="13">
                            <path fill="none" stroke="currentColor" stroke-linecap="square" d="M2.5 6.5h9"></path>
                            <circle fill="currentColor" cx="7" cy="3" r="1"></circle>
                            <circle fill="currentColor" cx="7" cy="10" r="1"></circle>
                        </svg>
                    </button>
                    <button :class="toolClass" @click="() => mathSymbol('-')">
                        <!-- minus -->
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" width="13" height="13">
                            <path fill="none" stroke="currentColor" stroke-linecap="square" d="M2.5 6.5h8"></path>
                        </svg>
                    </button>
                    <button :class="toolClass" @click="() => mathSymbol('+')">
                        <!-- plus -->
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" width="13" height="13">
                            <path fill="none" stroke="currentColor" stroke-linecap="square" d="M2.5 6.5h8m-4-4v8">
                            </path>
                        </svg>
                    </button>
                    <button :class="toolClass" @click="() => mathSymbol('*')">
                        <!-- multiply -->
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" width="13" height="13">
                            <path fill="none" stroke="currentColor" stroke-linecap="square" d="M3 10l7-7M3 3l7 7">
                            </path>
                        </svg>
                    </button>
                    <button :class="toolClass" @click="() => mathSymbol('ex')">
                        <!-- exponent -->
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" width="13" height="13">
                            <path fill="none" stroke="currentColor" stroke-linecap="square" d="M3 7l3.5-3.5L10 7">
                            </path>
                        </svg>
                    </button>
                    <button :class="toolClass" @click="() => mathSymbol('1/')">
                        <!-- 1/ -->
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" width="13" height="13">
                            <g fill="none" fill-rule="evenodd" stroke="currentColor">
                                <path stroke-linecap="square" stroke-linejoin="round" d="M3.5 10V2.5L1 5"></path>
                                <path stroke-linecap="square" d="M1.5 10.5h4"></path>
                                <path d="M8 12l3-11"></path>
                            </g>
                        </svg>
                    </button>
                </div>
            </div>
            <hr>

            <!-- Symbol Search Table -->
            <div class="symbolSearch">
                <!-- Filters -->
                <div class="filters">
                    <button :class="{ selectedOption: filter.type === option, filter: filter.type !== option }"
                        v-for="(filter, i) in filters" :key="i" @click="() => selectOption(i, filter)">
                        {{ filter.type }}
                    </button>
                </div>

                <!-- Sub Filters -->
                <div class="subFilters">
                    <button :class="subFilterClass" v-for="(subFilter, i) in subFilters" :key="i"
                        @click="() => selectSubOption(i)">
                        {{ subFilter }}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="18" height="18">
                            <path fill="currentColor"
                                d="M3.92 7.83L9 12.29l5.08-4.46-1-1.13L9 10.29l-4.09-3.6-.99 1.14Z"></path>
                        </svg>
                    </button>
                </div>

                <!-- Items Table -->
                <table class="items" v-if="info.length > 0">
                    <tbody>
                        <tr v-for="item in allSymbols" :key="item.symbol" @click="() => selectSymbol(item.symbol)">
                            <td>
                                <div class="tdSym">
                                    <div class="colorPattel" v-show="showColor">
                                        <span v-for="(color, index) in colors" :key="index">
                                            <span class="colorCircle" :style="{ backgroundColor: color }"
                                                @click="colorSlected(color, item.symbol)"></span>
                                        </span>
                                    </div>
                                    <span class="addWatchlist" @click="showColors">
                                        <svg fill="gray" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12"
                                            width="14" height="12" focusable="false" preserveAspectRatio="none">
                                            <path d="M14 12l-4-6 4-6H0v12z"></path>
                                        </svg>
                                    </span>

                                    <div class="tdImg">{{ item.symbol[0] }}</div>
                                    <p>{{ item.symbol }}</p>
                                </div>
                            </td>
                            <td>
                                <p>{{ item.companyName }}</p>
                            </td>
                            <td>
                                <div v-for="color in item.color" :key="color" class="color-circle"
                                    :style="{ backgroundColor: color }"></div>
                            </td>
                            <td class="tdCountry">
                                <p>{{ item.type }}</p>
                                <p>{{ item.exchange }}</p>
                                <img loading="lazy" :src="item.countryImage" class="roudedImg" alt="...">
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-else>
                    <p class="noResults">No results found</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import data from '../../data/data.json';
import axios from 'axios';
import {SERVER_URL} from '../constant.js'

export default {
    name: 'SymbolSearch',
    data() {
        return {
            symbol: "",
            filters: data.filtersForSymbolSearch,
            option: "All", // Tracks the selected filter option
            subFilters: data.filtersForSymbolSearch[0]?.subFilters || [], // Initialize with the subFilters of the first option
            subOption: 0,
            selectedSymbol: "",
            info: data.info,
            page: "symbolSearch",
            isDark: JSON.parse(localStorage.getItem("themeIsDark")) || this.getTheme,
            colors: ['red', 'blue', 'green', 'yellow', 'purple', 'Maroon', 'Olive', 'DarkGreen', 'white', 'Gray', 'Navy', 'orange', 'Brown', 'Chartreuse', 'Chocolate', 'Crimson', 'DarkBlue', 'DarkCyan', 'DarkGoldenRod', 'black'],
            showColor: false,
            // symbols user have in watchlist
            userSymbols: [],
            allSymbols: [],
            socket: {},
        };
    },
    computed: {
        getIsSymbolSearchOpen() {
            return this.$store.getters.getIsSymbolSearchOpen;
        },
        getSymbolSearch() {
            return this.$store.getters.getSymbolSearch;
        },
        getFocusInput() {
            return this.$store.getters.getFocusInput;
        },
        getTheme() {
            return this.$store.getters.getTheme;
        },
        mainClass() {
            return this.isDark ? "main-dark" : "main";
        },
        toolClass() {
            return this.isDark ? "tool-dark" : "tool";
        },
        inputClass() {
            return this.isDark ? "input-dark" : "input";
        },
        subFilterClass() {
            return this.isDark ? "subFilter-dark" : "subFilter";
        },
        // get socket from store
        getSocketConnection(){
            return this.$store.getters.getSocketConnection;
        }
    },
    async mounted() {
        this.$watch(
            () => this.getFocusInput,
            (newVal) => {
                if (newVal) {
                    this.$nextTick(() => {
                        this.$refs.myInput.focus();
                        this.$store.commit('setFocusInput', false); // Reset focus state
                    });
                }
            }
        );
        this.filteredInfo("all", this.symbol);

        const url = `${SERVER_URL}/api/watchlist/getAllSymbolsOfUserInWatchlist`
        const data = {
            email: localStorage.getItem('email')
        }
        await axios.post(url, data)
            .then((res) => {
                console.log("sankalp", res.data.symbols)
                this.userSymbols = res.data.symbols
            })
            .catch((err) => console.log(err))

        // console.log(this.info)
        // console.log(this.userSymbols)
        const ans = this.addColorsToSearchSymbol(this.info, this.userSymbols)
        this.allSymbols = ans
        // console.log(ans)
        // if this.info contain this.userSymbols that add color property to that symbol in this.info


        // socket code
        this.socket = this.getSocketConnection
        // getting user symbols from backend
        this.socket.on('userSymbols', (data) => {
            // console.log("sankalp : ", data)
            this.userSymbols = data
            const ans = this.addColorsToSearchSymbol(this.info, this.userSymbols)
            this.allSymbols = ans
        })
    },
    methods: {
        addColorsToSearchSymbol(arr1, arr2) {
            // Create a color map where each symbol maps to an array of colors
            const colorMap = arr2.reduce((acc, item) => {
                if (acc[item.symbol]) {
                    item.color.forEach(color => {
                        if (!acc[item.symbol].includes(color)) {
                            acc[item.symbol].push(color);
                        }
                    });
                } else {
                    acc[item.symbol] = [...item.color]; // Ensure it's a new array
                }
                return acc;
            }, {});

            // Map over arr1 and add the color array from the colorMap
            return arr1.map(item => {
                if (colorMap[item.symbol]) {
                    return { ...item, color: colorMap[item.symbol] };
                }
                return { ...item, color: [] }; // Ensure color is an array even if no match is found
            });
        },
        async colorSlected(color, symbol) {
            const data = {
                email: localStorage.getItem('email'),
                symbol: symbol,
                color: color,
                name: color,
            }

            // socket code
            console.log(this.socket)
            if (this.socket) {
                this.socket.emit(`addColorToSymbol`, data)
            }
            // api code
            // console.log(data)
            // const url = `${SERVER_URL}/api/watchlist/appendWatchlist`
            // await axios.post(url, data)
            //     .then((response) => {
            //         // console.log(response)
            //     })
            //     .catch((error) => {
            //         console.log(error)
            //     })
        },
        showColors() {
            this.showColor = !this.showColor
        },
        closeSymbolSearch() {
            this.$store.commit('updateIsSymbolSearchOpen', false);
            this.$store.commit('updateSymbolSearch', "");
        },
        updateSearch() {
            this.$store.commit('updateSymbolSearch', this.symbol);
            this.filteredInfo(this.option, this.symbol);
        },
        selectOption(index, filter) {
            this.option = filter.type;
            this.subFilters = this.filters[index]?.subFilters || []; // Update the subFilters based on the selected option
            this.subOption = 0; // Reset subOption when main option changes
            this.filteredInfo(filter.type, this.symbol);
        },
        selectSubOption(index) {
            this.subOption = index;
        },
        selectSymbol(symbol) {
            this.selectedSymbol = symbol;
            // console.log(symbol)
            this.$store.commit('updateSelectedSymbol', symbol);
        },
        filteredInfo(filterType, search) {
            if (filterType.toLowerCase() === 'all') {
                // Flatten the nested arrays from data.info
                this.info = Object.values(data.info).flat();
            } else {
                const info = data.info[filterType.toLowerCase()] || [];
                this.info = info;
            }

            if (search) {
                const lowerCaseSearch = search.toLowerCase();
                this.info = this.info.filter(item =>
                    item.symbol.toLowerCase().includes(lowerCaseSearch) ||
                    item.companyName?.toLowerCase().includes(lowerCaseSearch) ||
                    item.exchange?.toLowerCase().includes(lowerCaseSearch) ||
                    item.countryName?.toLowerCase().includes(lowerCaseSearch)
                );
            }
        },
        mathSymbol(operator) {
            if (operator === '+') {
                this.symbol = this.symbol + "+"
            }
            if (operator === '-') {
                this.symbol = this.symbol + "-"
            }
            if (operator === '*') {
                this.symbol = this.symbol + "*"
            }
            if (operator === '/') {
                this.symbol = this.symbol + "/"
            }
            if (operator === '1/') {
                this.symbol = "1/" + this.symbol
            }
            if (operator === 'ex') {
                this.symbol = this.symbol + "^"
            }
        },
    },
    watch: {
        getSymbolSearch(newSymbol) {
            this.symbol = newSymbol;
        },
        getTheme(newTheme) {
            this.isDark = newTheme;
        },
    }
};
</script>


<style scoped>
.color-circle {
    width: 10px;
    /* Adjust the size as needed */
    height: 10px;
    border-radius: 50%;
    /* Makes the div a circle */
    display: inline-block;
    /* Ensures the div behaves like an inline element */
    margin-right: 5px;
    /* Adds space between circles */
}

.tdSym:hover .colorPattel {
    visibility: visible;
}

.colorPattel {
    display: flex;
    gap: 5px;
    padding: 5px 10px;
    position: absolute;
    background-color: #4e4e4e;
    left: 30px;
    visibility: hidden;
    width: 300px;
    overflow-x: scroll;
}

.colorCircle {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.colorCircle:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.3) !important;
}

.page {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(50, 59, 40, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1005;
}

.main {
    width: 60%;
    height: 80%;
    background-color: white;
    border-radius: 10px;
    position: relative;
    padding: 10px;
    overflow-y: scroll;
}

.main-dark {
    width: 60%;
    height: 80%;
    background-color: #1e1e1e;
    border-radius: 10px;
    position: relative;
    padding: 10px;
    overflow-y: scroll;
}

hr {
    border: none;
    border-top: 1px solid #e0e3eb;
    height: 1px;
}

.cross {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
    border-radius: 10px;
    color: #aeb0b3;
}

.cross:hover {
    background-color: #76878319;
}

.search {
    display: flex;
    align-items: center;
    gap: 10px;
}

.input {
    width: 70%;
    height: 20px;
    border: none;
    outline: none;
    text-transform: uppercase;
    border-right: 1px solid #e0e3eb;
    padding-right: 10px;
    background-color: transparent;
}

.input-dark {
    width: 70%;
    height: 20px;
    border: none;
    outline: none;
    text-transform: uppercase;
    border-right: 1px solid #e0e3eb;
    padding-right: 10px;
    background-color: transparent;
    color: #fff;
}

.tools {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.tool {
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tool-dark {
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.tool:hover {
    background-color: #76878319;
}

.tool-dark:hover {
    background-color: #76878319;
}

.filters {
    display: flex;
    align-items: center;
    gap: 1px;
}

.filter {
    padding: 4px 15px;
    border-radius: 14px;
    font-weight: 400;
    border: none;
    background-color: #f0f3fa;
}

.filter:hover {
    background-color: #e9ecf0;
}

.selectedOption {
    background-color: black;
    color: white;
    padding: 4px 15px;
    border-radius: 14px;
    font-weight: 400;
    border: none;
}

.subFilters {
    display: flex;
    align-items: center;
    gap: 5px;
}

.subFilter {
    margin-top: 10px;
    padding: 4px 15px;
    border-radius: 5px;
    font-weight: 400;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
}

.subFilter-dark {
    margin-top: 10px;
    padding: 4px 15px;
    border-radius: 5px;
    font-weight: 400;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    color: #ccc;
}

.subFilter:hover {
    background-color: #e9ecf0;
}

.subFilter-dark:hover {
    background-color: #602d2d;
}

.data {
    margin-top: 20px;
}

.items {
    width: 100%;
    margin-top: 20px;
}

table,
tbody {
    width: 100%;
}

tr:hover {
    background-color: #2a2e39;
}

.tdSym {
    display: flex;
    align-items: center;
    gap: 2px;
}

.addWatchlist {
    visibility: hidden;
}

.tdSym:hover .addWatchlist {
    visibility: visible;
}

.tdCountry {
    display: flex;
    gap: 5px;
    align-items: center;
}

.roudedImg {
    border-radius: 100%;
    height: 20px;
    width: 20px;
}

.tdImg {
    background-color: #e0e3eb;
    border-radius: 100%;
    padding: 5px;
    font-weight: bold;
    color: white;
}

@media (max-width: 900px) {
    .main {
        width: 90%;
    }

    .main-dark {
        width: 90%;
    }

    .input {
        width: 60%;
    }

    .tool {
        height: 20px;
        width: 15px;
    }
}

@media (max-width: 500px) {
    .main {
        width: 100%;
    }

    .main-dark {
        width: 90%;
    }

    .tool {
        height: 20px;
        width: 10px;
    }

    .input {
        width: 60%;
    }
}
</style>