var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("trading-vue", {
        ref: "tradingVue",
        attrs: {
          data: _vm.chart,
          width: _vm.width,
          height: _vm.height,
          toolbar: true,
          "color-back": _vm.colors.colorBack,
          "color-grid": _vm.colors.colorGrid,
          "color-text": _vm.colors.colorText,
          chartsTf: _vm.chartsTf,
          TFfunction: _vm.handleTFfunction,
          overlays: _vm.overlays,
          extensions: _vm.ext,
          "legend-buttons": [
            "display",
            "settings",
            "up",
            "down",
            "add",
            "remove",
          ],
          "x-settings": _vm.xsett,
          "chart-config": { DEFAULT_LEN: 70 },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }