import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)
// Create a new store instance.
const store = new Vuex.Store({
    state() {
        return {
            // if user is logedin
            user : null,

            //for right tool bar
            rightToolBarWidth: 56,
            isInfoOpen : false,
            toolsSection: 56,
            bottomBar : 35,
            showBar: JSON.parse(localStorage.getItem("showRightBar")) || false,

            // for time frame (numbers are indices )
            timeFrame: 0,

            // for SymbolSearch
            selectedSymbol: "TCS",
            symbolSearch: '',
            isSymbolSearchOpen: false, // make true by default for development of symbol search 
            focusInput: false,

            // for theme
            isDark: JSON.parse(localStorage.getItem("themeIsDark")) || false,

            // if there is any input box of type text then use this isOtherInputSearchOpen because when isOtherInputSearchOpen this is false keyboard listener will open symbolSeearch
            isOtherInputSearchOpen: false,

            IsAccountSettingsOpen: JSON.parse(localStorage.getItem("isAccountSettingsPageOpen")) || false,
            
            // for signup popup
            JoinUsPopup: false, 


            //for watchlist
            isWatchlistOpen: false,
            FocusInputOnWatchlist: false,
            selectedWatchlist: {},

            // socket connection
            socket : {},
        }
    },
    mutations: {
        // for user
        setUser(state, user) {
            state.user = user
        },

        //for right tool bar
        updateRightToolBarWidth(state, width) {
            // console.log(width)
            state.rightToolBarWidth = width;
        },
        toggleInfoOpen(state, isOpen){
            state.isInfoOpen = isOpen;
        },
        updateBottomHeight(state, height){
            state.bottomBar = height;
        },
        toggleShowBar(state, show){
            localStorage.setItem("showRightBar", show)
            state.showBar = show;
        },

        // for time frame
        updateTimeFrame(state, tf){
            // console.log(tf)
            localStorage.setItem('tf', tf)
            state.timeFrame = tf;
        },

        // for SymbolSearch
        updateSymbolSearch(state, symbol) {
            state.symbolSearch = symbol;
        },
        updateIsSymbolSearchOpen(state, bool) {
            state.isSymbolSearchOpen = bool;
        },
        setFocusInput(state, value) {
            state.focusInput = value;
        },
        updateSelectedSymbol(state, symbol) {
            state.selectedSymbol = symbol;
            console.log(symbol)
        },

        // for theme
        toggleTheme(state, theme) {
            localStorage.setItem('themeIsDark', theme)
            state.isDark = theme;
        },


        updateIsOtherInputSearchOpen(state, bool) {
            state.isOtherInputSearchOpen = bool;
        },
        updateIsAccountSettingsOpen(state, bool){
            localStorage.setItem('isAccountSettingsPageOpen', bool)
            state.IsAccountSettingsOpen = bool;
        },

        // for signup popup
        updateJoinUsPopup(state, bool){
            console.log("show popup")
            state.JoinUsPopup = bool;
        },

        // for watchlist
        updateIsWatchlistOpen(state, bool){
            console.log(bool)
            state.isWatchlistOpen = bool;
        },
        setFocusInputOnWatchlist(state, value) {
            state.focusInput = value;
        },
        setSelectedWatchlist(state, watchlist){
            console.log(watchlist)
            localStorage.setItem('watchlistSelected', JSON.stringify({color: watchlist.color, name: watchlist.name}))
            state.selectedWatchlist = {color: watchlist.color, name: watchlist.name};
        },

        // socket connection
        UpdateSocketConnection(state, socket){
            state.socket = socket
        }
    },
    actions: {
        // for SymbolSearch
        triggerFocusInput({ commit }) {
            commit('setFocusInput', true);
        },

        //for watchlist
        triggerFocusInputOnWatchlist({ commit }) {
            commit('setFocusInputOnWatchlist', true);
        },

    },
    getters: {
        // for user
        getUser : state => {
            return state.user;
        },

        //for right tool bar
        rightToolBarWidth: state => {
            return state.rightToolBarWidth
        },
        isInfoOpen: state => {
            return state.isInfoOpen
        },
        getBottomBarHeight: state => {
            return state.bottomBar
        },
        getShowBar: state => {
            return state.showBar
        },

        // for time frame
        getTimeFrame: state => {
            return state.timeFrame
        },

        // for SymbolSearch
        getSymbolSearch: state => {
            return state.symbolSearch
        },
        getIsSymbolSearchOpen: state => {
            return state.isSymbolSearchOpen
        },
        getFocusInput: state => {
            return state.focusInput
        },
        getSelectedSymbol: state => {
            return state.selectedSymbol
        },

        // for theme
        getTheme : state => {
            return state.isDark 
        },
        
        getIsOtherInputSearchOpen: state => {
            return state.isOtherInputSearchOpen
        },
        getIsAccountSettingsPageOpen: state => {
            return state.IsAccountSettingsOpen
        },

        // for signup popup
        getJoinUsPopup : state => {
            return state.JoinUsPopup;
        },

        //for watchlist
        getisWatchlistOpen: state => {
            return state.isWatchlistOpen
        },
        getFocusInputOnWatchlist: state => {
            return state.FocusInputOnWatchlist
        },
        getSelectedWatchlist: state => {
            return state.selectedWatchlist
        },

        // for sockets
        getSocketConnection: state => {
            return state.socket
        }
    }
})

export default store