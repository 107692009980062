<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
* {
  box-sizing: border-box !important;
}

/* styles for toast box */
.Vue-Toastification__toast--default.my-custom-toast-class {
    background-color: gray;
    color: white;
    height: 10px;
}

.Vue-Toastification__toast-body.custom-class-1 {
    font-size: 12px;
}

.Vue-Toastification__toast-component-body.custom-class-2 {
    width: 100%;
}
</style>
