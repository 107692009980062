<template>
  <div>
    <trading-vue
      :data="chart"
      :width="width"
      :height="height"
      :toolbar="true"
      :color-back="colors.colorBack"
      :color-grid="colors.colorGrid"
      :color-text="colors.colorText"
      ref="tradingVue"
      :chartsTf="chartsTf"
      :TFfunction="handleTFfunction"
      :overlays="overlays"
      :extensions="ext"
      :legend-buttons="['display', 'settings', 'up', 'down', 'add', 'remove']"
      :x-settings="xsett"
      :chart-config="{ DEFAULT_LEN: 70 }"
    >
    </trading-vue>
  </div>
</template>

<script>
import TradingVue from "./TradingVue.vue";
import Data from "../data/data.json";
import DataCube from "../src/helpers/datacube.js";
import Overlays from "tvjs-overlays";
import XP from "tvjs-xp";
import DataTf from "../data/data_tf.json";
import {io} from 'socket.io-client'
import {SERVER_URL} from './constant.js'

export default {
  name: "Home",
  props: ["night"],
  components: {
    TradingVue,
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
    // unComment it if you work on timeframe feature
    //this function gets value from tradingVue.vue (change from TfSelector.vue reflected here)
    handleTFfunction(selectedTime) {
      this.timeframe = selectedTime.name;
      this.chart.set("chart.data", DataTf[this.timeframe]);
      this.$refs.tradingVue.resetChart();
      this.log_scale = false;
    },
  },
  data() {
    return {
      // socket connection
      socket: {},

      // unComment it if you work on timeframe feature
      // chartsTf: DataTf,
      // chart: new DataCube({}),
      // unComment it if you work on timeframe feature

      // Comment it if you work on timeframe feature
      charts: Data,
      chart: new DataCube(Data),
      //Comment it if you work on timeframe feature

      width: window.innerWidth,
      height: window.innerHeight,
      log_scale: false,
      index_based: false,
      xsett: {
        "grid-resize": { min_height: 30 },
      },
      overlays: Object.values(Overlays),
      ext: Object.values(XP),
      timeframe: "",
    };
  },
  created(){
    // make socket connect to our backend
    this.socket = io(`${SERVER_URL}`)
    // console.log(this.socket)
    this.$store.commit('UpdateSocketConnection', this.socket)
    this.socket.on('checking', (msg) => {
      console.log(msg)
    })
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
    window.dc = this.chart;
    window.tv = this.$refs.tradingVue;
  },
  computed: {
    // will store the latest price
    currentValue() {
      if (this.chart.data.chart) return this.chart.data.chart.data.at(-1)[4];
      // else return 0;
    },

    // TODO: theme switch directly use store
    colors() {
      return this.getTheme
        ? {
            colorBack: "#1e1e1e", // Dark background for better contrast
            colorGrid: "#333", // Slightly lighter grid lines
            colorText: "#ccc", // Light text color for readability
          }
        : {
            colorBack: "#fff",
            colorGrid: "#eee",
            colorText: "#333",
          };
    },
    getTheme() {
      return this.$store.getters.getTheme;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    currentValue(newVal, oldVal) {
      console.log("Price changed");
    },
    log_scale(value) {
      if (this.chart.data.chart) {
        this.$set(this.chart.data.chart, "grid", {
          logScale: value,
        });
      }
    },
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}
::backdrop {
  background-color: white;
}
</style>
