<template>
  <!-- Remove it when alert feature is ready-->
  <div class="coming-soon-page" v-show="visible">
    <div class="wrapper">COMING SOON</div>
  </div>

  <!-- <div class="alert-page" v-show="visible">
    <div class="alert-dialog-box">
      <div class="header">
        <span>Create alert on {{ $store.getters.getSelectedSymbol }}</span>
        <span class="cross" @click="initialState">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
            width="18"
            height="18"
          >
            <path
              stroke="currentColor"
              stroke-width="1.2"
              d="m1.5 1.5 15 15m0-15-15 15"
            ></path>
          </svg>
        </span>
      </div>

      <div class="body">
        <div class="tabs">
          <span class="tab-item active-tab"> Settings </span>
          <span class="tab-item"> Notifications </span>
        </div>
        <div>
          <div class="top">
            <div class="legend">Condition</div>

            <div class="fields">
              <div class="alert-item">
                <div class="design bg-dark">
                  <div>{{ $store.getters.getSelectedSymbol }}</div>
                </div>
              </div>
              <div class="alert-item">
                <select name="status" v-model="status">
                  <option value="Crossing">Crossing</option>
                  <option value="Crossing Up">Crossing Up</option>
                  <option value="Crossing Down">Crossing Down</option>
                  <option value="Greater Than">Greater Than</option>
                  <option value="Less Than">Less Than</option>
                </select>
              </div>
              <div class="alert-item">
                <div class="price-box">
                  <div class="price-box-item bg-dark">Price</div>
                  <div class="price-box-item">
                    <input
                      type="number"
                      min="0"
                      v-model="price"
                      class="price"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="middle">
            <div class="row">
              <div class="legend">Trigger</div>
              <div class="fields">
                <div class="alert-item">
                  <span
                    class="m1"
                    :class="{
                      'bg-dark': trigger === 'once',
                    }"
                    @click="setTrigger('once')"
                    >Only Once</span
                  >
                  <span
                    class="m1"
                    :class="{
                      'bg-dark': trigger === 'every time',
                    }"
                    @click="setTrigger('every time')"
                    >Every Time</span
                  >
                </div>
              </div>
            </div>
            <div class="row cal-row">
              <div class="legend">Expiration</div>
              <div class="fields">
                <div class="alert-item">
                  Calender
                </div>
              </div>
            </div>
          </div>

          <div class="bottom">
            <div class="row">
              <div class="legend">Alert</div>
              <div class="fields">
                <div class="alert alert-item">
                  <input
                    type="text"
                    :v-model="alertTitle"
                    :value="alertTitle"
                    @change="meth"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="legend">Message</div>
              <div class="fields">
                <div class="alert alert-item">
                  <textarea
                    :value="alertMessage"
                    :v-model="customAlertMessage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="btn">
          <button class="btn1" @click="initialState">Cancel</button>
        </div>
        <div class="btn">
          <button class="btn2" @click="createAlert">Create</button>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
import Alert from "./primitives/alert.js";

export default {
  name: "AlertBox",
  data() {
    return {
      visible: false,
      trigger: "once",
      alertTitle: "Alert Title",
      status: "Crossing",
      price: 0,
      customAlertMessage: "",
    };
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.visible = false;
        }, 2000);
      }
    },
  },
  computed: {
    alertMessage() {
      return `${this.$store.getters.getSelectedSymbol} ${this.status} ${this.price} `;
    },
  },
  mounted() {
    this.$bus.$on("show_alert_box", () => {
      this.visible = true;
    });
  },
  methods: {
    setTrigger(val) {
      this.trigger = val;
    },

    meth(e) {
      e.preventDefault();
    },
    initialState() {
      this.trigger = "once";
      this.alertTitle = "Alert Title";
      this.status = "Crossing";
      this.price = 0;
      this.customAlertMessage = "";
      this.visible = false;
    },

    createAlert() {
      if (!localStorage.getItem("alerts")) {
        localStorage.setItem("alerts", JSON.stringify([]));
      }
      let alerts = JSON.parse(localStorage.getItem("alerts"));
      alerts.push(
        new Alert(
          this.$store.getters.getSelectedSymbol,
          this.price,
          this.status,
          this.alertTitle,
          this.message,
          this.trigger
        )
      );

      localStorage.setItem("alerts", JSON.stringify(alerts));
    },
  },
};
</script>

<style scoped>
.coming-soon-page {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper {
  z-index: 2000;
  background-color: white;
  border-radius: 5px;
  font-size: 40px;
  filter: drop-shadow(1px 2px 4px hsl(0, 0%, 51%));
  padding: 15px;
  height: auto;
  color: rgb(63, 63, 63);
  text-shadow: 1px 1px 1px #000;
  border: 2px solid rgb(174, 174, 174);
}

.alert-page {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-dialog-box {
  width: 26vw;
  z-index: 2000;
  background-color: white;
  border-radius: 5px;
  font-size: clamp(14px, 1vw, 25px);
  filter: drop-shadow(1px 2px 4px hsl(0, 0%, 51%));
  padding: 5px;
  height: auto;
  /* min-width: 270px; */
  max-width: 500px;
}

.cross {
  padding: 2.5px 5px 2.5px 5px;
  border-radius: 5px;
}
.cross:hover {
  background-color: rgb(209, 209, 209);
}

.bg-dark {
  background-color: rgb(209, 209, 209);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  font-size: clamp(20px, 1.3vw, 30px);
  font-weight: 700;
  margin: 5px;
}

.body {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  width: 100%;
  /* font-size: clamp(16px, 1vw, 25px); */
}

.tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  font-weight: 700;
}

.tab-item {
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
  padding-bottom: 3px;
}

.active-tab {
  border-bottom: 4px solid black;
}

.top {
  display: flex;
  margin: 10px 5px 10px 5px;
  padding: 5px;
  border-bottom: 1px solid rgb(151, 151, 151);
  width: calc(100%-20px);
}

.legend {
  /* flex-grow: 2; */
  /* min-width: 80px;
  max-width: 120px;
  width: 8vw; */
  /* flex-basis: 100px;
  flex-grow: 1; */
  width: 25%;
  margin-right: 10px;
}

.fields {
  width: 74%;
  /* flex-grow: 1; */
  /* width: 15vw;
  min-width: 160px;
  max-width: 200px; */
}

.alert-item {
  width: 100%;
  margin: 5px 2px 5px 2px;
  /* min-width: 150px; */
  display: flex;
  align-items: center;
}
.design {
  width: 96%;
  /* width: 15vw; */
  height: auto;
  font-size: inherit;
  padding: 2.5px 5px 2.5px 5px;
  border: 1px solid rgb(153, 153, 153);
  border-radius: 5px;
  display: flex;
  align-items: center;
  /* min-width: 150px; */
  /* max-width: 250px; */
}

.alert-item select {
  /* width: 15vw; */
  width: 96%;
  /* min-width: 150px; */
  height: auto;
  font-size: inherit;
  padding: 2.5px 5px 2.5px 5px;
  border: 1px solid rgb(153, 153, 153);
  border-radius: 5px;
  /* max-width: 250px; */
}
.flex {
  display: flex;
}

.price-box {
  width: 98%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.price-box-item {
  width: 50%;
  padding: 5px;
  border-radius: 5px;
}
.price {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  font-size: inherit;
  border: 1px solid rgb(151, 151, 151);
}

.middle {
  display: flex;
  flex-direction: column;
  margin: 10px 5px 10px 5px;
  padding: 5px;
  border-bottom: 1px solid rgb(151, 151, 151);
  width: calc(100%-20px);
}

.row {
  display: flex;
  width: 100%;
  height: auto;
}

.mode {
  display: flex;
  /* height: 100%; */
  border: 1px solid rgb(180, 180, 180);
  border-radius: 5px;
  padding: 2.5px;
  /* width: 100%; */
}

.m1 {
  width: 48%;
  /* flex-grow: 1;*/
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 50%; */
  border-radius: 5px;
  padding: 2.5px;
  cursor: pointer;
}
.m2 {
  width: 45%;
  /* flex-grow: 1;
  display: flex;
  width: 40%;
  justify-content: center;
  align-items: center;
  height: 50%;
  border-radius: 5px;
  padding: 2.5px;
  cursor: pointer; */
}

.bottom {
  display: flex;
  width: calc(100%-20px);
  flex-direction: column;
  margin: 10px 5px 0px 5px;
  padding: 5px;
  border-bottom: 1px solid rgb(151, 151, 151);
}

.alert input,
textarea {
  width: 96%;
  padding: 2.5px 5px 2.5px 5px;
  border: 1px solid rgb(153, 153, 153);
  border-radius: 5px;
  resize: none;
  font-size: inherit;
}

.alert textarea {
  height: 60px;
  padding: 5px;
}

.footer {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0px 5px 0px 5px;
  padding: 2.5px 5px 2.5px 5px;
}

.btn {
  margin: 0px 5px 0px 5px;
}

.btn1 {
  padding: 5px;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  height: 30px;
  width: 60px;
  font-size: 14px;
  border: 1px solid rgb(158, 158, 158);
  font-weight: 600;
  color: rgb(55, 55, 55);
}
.btn2 {
  padding: 5px;
  border-radius: 5px;
  background-color: rgb(91, 91, 255);
  cursor: pointer;
  height: 30px;
  width: 60px;
  font-size: 14px;
  color: white;
  border: 1px solid rgb(91, 91, 255);
  font-weight: 600;
}

.btn1:hover {
  background-color: rgb(244, 244, 244);
}

.btn2:hover {
  background-color: rgb(66, 66, 255);
}
@media screen and (max-width: 1200px) {
  .alert-dialog-box {
    width: 60vw;
  }
}
@media screen and (max-width: 500px) {
  .alert-dialog-box {
    width: 80vw;
  }
}
</style>
