<script>
import Overlay from "../../mixins/overlay.js";
import Tool from "../../mixins/tool.js";
import Icons from "../../stuff/icons.json";
import Pin from "../primitives/pin.js";
import SqPin from "../primitives/SqPin.js";
import LongPosition from "../primitives/longPosition";
import ShortPosition from "../primitives/shortPosition";

export default {
  name: "LineTool",
  mixins: [Overlay, Tool],
  data() {
    return {
      id: 0,
    };
  },

  // Define internal setting & constants here
  computed: {
    sett() {
      return this.$props.settings;
    },
    p1() {
      return this.$props.settings.p1;
    },
    p2() {
      return this.$props.settings.p2;
    },
    p3() {
      return this.$props.settings.p3;
    },
    p4() {
      return this.$props.settings.p4;
    },
    line_color() {
      return this.sett.lineColor || "#000000"; // Default line color
    },
    line_width() {
      return 0.5;
    },
    color() {
      return this.sett.color || "#42b28a";
    },
  },
  methods: {
    meta_info() {
      return { author: "dhruv-atiuttam", version: "1.1.0" };
    },
    tool() {
      return {
        // Descriptor for the tool
        group: "Forecast",
        icon: Icons["longposition.svg"],
        type: "Long Position",
        hint: "This hint will be shown on hover",
        data: [], // Default data
        settings: {
          lineColor: "black",
        }, // Default settings
        // Modifications
        mods: {
          ShortPosition: {
            settings: {
              shortPos: true,
            },
            icon: Icons["shortposition.svg"],
          },
        },
      };
    },
    // Called after overlay mounted
    init() {
      const layout = this.$props.layout;

      let p1 = new Pin(this, "p1", {
        emit: true, // now other pins will be changed based on this pin
        id: this.id,
      });
      let p2 = new SqPin(this, "p2", {
        direction: "horizontal",
        id: this.id,
      });
      let p3 = new SqPin(this, "p3", {
        direction: "vertical",
        id: this.id,
      });
      let p4 = new SqPin(this, "p4", {
        direction: "vertical",
        id: this.id,
      });

      this.pins.push(p1);
      p2.update_from(
        [layout.screen2t(layout.t2screen(p1.t) + 120), p1.y$],
        true
      ); // initially 7 candles will be within tool (long pos/short pos)
      this.pins.push(p2);
      p3.update_from(
        [p1.t, layout.screen2$(layout.$2screen(p1.y$) - 120)],
        true
      );
      this.pins.push(p3);
      p4.update_from(
        [p1.t, layout.screen2$(layout.$2screen(p1.y$) + 120)],
        true
      );
      this.pins.push(p4);

      this.pins.forEach((pin) =>
        pin.on("settled", () => this.set_state("finished"))
      );

      if (this.sett.shortPos) this.other.push(this.draw_essentials_short);
      else this.other.push(this.draw_essentials_long);
      this.$emit("drawing-mode-off");
      this.id++;
    },
    draw(ctx) {
      ctx.lineWidth = this.line_width;
      ctx.strokeStyle = this.line_color;

      if (this.sett.shortPos)
        new ShortPosition(this, ctx).draw(this.p1, this.p2, this.p3, this.p4);
      else new LongPosition(this, ctx).draw(this.p1, this.p2, this.p3, this.p4);

      this.render_pins(ctx);
    },

    draw_essentials_long(ctx) {
      const layout = this.$props.layout;
      ctx.lineWidth = this.line_width;
      ctx.strokeStyle = this.line_color;

      let x1 = layout.t2screen(this.p1[0]);
      let y1 = layout.$2screen(this.p1[1]);
      let x2 = layout.t2screen(this.p2[0]);
      let y2 = layout.$2screen(this.p2[1]);
      let x3 = layout.t2screen(this.p3[0]);
      let y3 = layout.$2screen(this.p3[1]);
      let x4 = layout.t2screen(this.p4[0]);
      let y4 = layout.$2screen(this.p4[1]);

      // Drawing text boxes with calculated values
      const padding = 5;
      const fontSize = 12;
      ctx.font = `${fontSize}px Arial`;

      const target = this.p3[1] - this.p1[1];
      const stop = this.p1[1] - this.p4[1];
      const risk_reward_ratio = target / stop;

      const open_p_l = 200;
      const target_amount = risk_reward_ratio * 750;

      const stop_percent = (stop / this.p1[1]) * 100;
      const target_percent = (target / this.p1[1]) * 100;

      const targetValue = `Target: ${target.toFixed(
        2
      )} (${target_percent.toFixed(2)}%) ${(target * 20).toFixed(
        0
      )} Amount: ${target_amount.toFixed(2)}`;
      const openPLValue = `Open P&L: ${open_p_l.toFixed(
        2
      )}, Qty: 0, Risk/Reward Ratio: ${risk_reward_ratio.toFixed(2)}`;
      const stopValue = `Stop: ${stop.toFixed(2)} (${stop_percent.toFixed(
        2
      )}%) ${(stop * 20).toFixed(0)} Amount: 750`;

      // Draw target value box (top-right)
      this.drawTextBox(ctx, targetValue, x3 + 10, y3 - 10, "#2a9d8f");

      // Draw open P&L value box (center)
      this.drawTextBox(
        ctx,
        openPLValue,
        x1 + 10,
        (y3 + y4) / 2 - 10,
        "#e76f51"
      );

      // Draw stop value box (bottom-right)
      this.drawTextBox(ctx, stopValue, x4 + 10, y4 + 20, "#e63946");
    },

    draw_essentials_short(ctx) {
      const layout = this.$props.layout;
      ctx.lineWidth = this.line_width;
      ctx.strokeStyle = this.line_color;

      let x1 = layout.t2screen(this.p1[0]);
      let y1 = layout.$2screen(this.p1[1]);
      let x2 = layout.t2screen(this.p2[0]);
      let y2 = layout.$2screen(this.p2[1]);
      let x3 = layout.t2screen(this.p3[0]);
      let y3 = layout.$2screen(this.p3[1]);
      let x4 = layout.t2screen(this.p4[0]);
      let y4 = layout.$2screen(this.p4[1]);

      // Drawing text boxes with calculated values
      const padding = 5;
      const fontSize = 12;
      ctx.font = `${fontSize}px Arial`;

      const target = this.p1[1] - this.p4[1];
      const stop = this.p3[1] - this.p1[1];
      const risk_reward_ratio = target / stop;

      const open_p_l = 200;
      const target_amount = risk_reward_ratio * 750;

      const stop_percent = (stop / this.p1[1]) * 100;
      const target_percent = (target / this.p1[1]) * 100;

      const targetValue = `Target: ${target.toFixed(
        2
      )} (${target_percent.toFixed(2)}%) ${(target * 20).toFixed(
        0
      )} Amount: ${target_amount.toFixed(2)}`;
      const openPLValue = `Open P&L: ${open_p_l.toFixed(
        2
      )}, Qty: 0, Risk/Reward Ratio: ${risk_reward_ratio.toFixed(2)}`;
      const stopValue = `Stop: ${stop.toFixed(2)} (${stop_percent.toFixed(
        2
      )}%) ${(stop * 20).toFixed(0)} Amount: 750`;

      // Draw target value box (top-right)
      this.drawTextBox(ctx, stopValue, x3 + 10, y3 - 10, "#2a9d8f");

      // Draw open P&L value box (center)
      this.drawTextBox(
        ctx,
        openPLValue,
        x1 + 10,
        (y3 + y4) / 2 - 10,
        "#e76f51"
      );

      // Draw stop value box (bottom-right)
      this.drawTextBox(ctx, targetValue, x4 + 10, y4 + 20, "#e63946");
    },
    // Function to draw text box
    drawTextBox(ctx, text, x, y, backgroundColor) {
      const padding = 5;
      const fontSize = 12;

      const textWidth = ctx.measureText(text).width;
      const textHeight = fontSize; // Approximate text height

      ctx.fillStyle = backgroundColor;
      ctx.fillRect(
        x - padding,
        y - textHeight - padding,
        textWidth + 2 * padding,
        textHeight + 2 * padding
      );

      ctx.fillStyle = "#fff"; // White color for text
      ctx.fillText(text, x, y);
    },

    use_for() {
      return ["Forecast"];
    },
    data_colors() {
      return [this.color];
    },
  },
};
</script>
