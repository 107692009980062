import Vue from "vue";

import router from "./components/js/router.js";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import store from './store/index.js'

const options = {
  icon: false,
  timeout: 2000,
  hideProgressBar: true,
};

// Default File to use 💎
import App from "./App.vue";
import $bus from "./stuff/events";
// MOB_DEBUG=true npm run test - Enables mobile debugging
// (sending console output to the webpack terminal)
// if (MOB_DEBUG) {
//     console.log = debug
//     console.error = debug
//     console.warn = debug
// }
Vue.use(Toast, options);
Vue.prototype.$bus = $bus;
new Vue({
  router,
  store,  // Inject the Vuex store configuration
  el: "#app",
  render: (h) => h(App),
});

function debug(...argv) {
  fetch("/debug?argv=" + JSON.stringify(argv));
}
