var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.pageClass }, [
    _c("div", { staticClass: "mainComponent" }, [
      _c("p", { class: _vm.companyNameClass }, [
        _c("img", {
          staticClass: "logo",
          attrs: {
            src: "https://res.cloudinary.com/dzd8l9wym/image/upload/v1722250225/iaennuve3ukfiptsqr1d.png",
            alt: "logo",
          },
        }),
        _vm._v("\n            Tradilent\n        "),
      ]),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "register" },
        [
          _vm._v("\n            Have an account ?\n            "),
          _c(
            "router-link",
            { class: _vm.signinClass, attrs: { to: "/signup" } },
            [_vm._v("Sign Up")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.formFrameClass },
        [
          _c(
            "router-link",
            { class: _vm.backClass, attrs: { to: "/signin" } },
            [
              _vm._v("◀ "),
              _c("span", { staticClass: "backText" }, [_vm._v("Go Back")]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "form" }, [
            _c("h3", { class: _vm.h3Class }, [_vm._v("Enter One Time Code")]),
            _vm._v(" "),
            _c("p", { class: _vm.notifyClass }, [
              _vm._v(
                "\n                    Enter 6-digit code we’ve sent to\n                    "
              ),
              _c("span", { staticClass: "email" }, [_vm._v(_vm._s(_vm.email))]),
              _vm._v(" to confirm your email address\n                "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "opt" },
              _vm._l(_vm.otpArray, function (value, index) {
                return _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.otpArray[index],
                      expression: "otpArray[index]",
                    },
                  ],
                  key: index,
                  ref: `otp${index}`,
                  refInFor: true,
                  staticClass: "otp-input",
                  attrs: { type: "text", maxlength: "1" },
                  domProps: { value: _vm.otpArray[index] },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.otpArray, index, $event.target.value)
                      },
                      function ($event) {
                        return _vm.onInput(index)
                      },
                    ],
                    keydown: function ($event) {
                      return _vm.onKeydown($event, index)
                    },
                    paste: function ($event) {
                      return _vm.onPaste($event)
                    },
                  },
                })
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "signIn", on: { click: _vm.verifyOTP } },
              [_vm._v("Verify Signup Address")]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("p"),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "contant" }, [
      _c("p", [_vm._v("Contact us")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }