<template>
  <div class="tfSelector">
    <select v-model="selected" :class="selectorClass" @change="onSelectChange">
      <option v-for="(tf, i) in timeframes" :key="i" :value="i">
        {{ tf }}
      </option>
    </select>
  </div>
</template>

<script>
import data_tf from '../../../data/data_tf.json';

export default {
  name: "TfSelector",
  props: {
    chartsTf: {
      type: Array,
      default: () => [],
    },
    TFfunction: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      selected: localStorage.getItem('tf') || 9, // Default to 1H
      isDark: JSON.parse(localStorage.getItem("themeIsDark")) || this.getTheme,
    };
  },
  computed: {
    timeframes() {
      return Object.keys(data_tf);
    },
    selectorClass() {
      return this.isDark ? "select-dark" : "select";
    },
    getTheme() {
      return this.$store.getters.getTheme;
    },
  },
  mounted() {
    this.emitSelection();
  },
  methods: {
    onSelectChange() {
      if(!JSON.parse(localStorage.getItem('isLogin'))){
        this.$store.commit('updateJoinUsPopup', true)
        return
      }
      this.emitSelection();
    },
    emitSelection() {
      const selectedTf = {
        name: this.timeframes[this.selected],
        i: this.selected,
      };
      if (this.TFfunction) {
        this.TFfunction(selectedTf);
      }
      this.$store.commit("updateTimeFrame", this.selected);
      localStorage.setItem('tf', this.selected);
    },
  },
  watch: {
    getTheme(newTheme) {
      this.isDark = newTheme;
    },
  },
};
</script>

<style scoped>
.tfSelector {
  background: transparent;
  border-radius: 3px;
  border: none;
}
.select-dark {
  background: transparent;
  border: none;
  outline: none;
  color: #ccc;
}
.select {
  background: transparent;
  border: none;
  outline: none;
}
option {
  background: white;
}
</style>
