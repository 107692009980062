<template>
    <div :class="pageClass">
        <div class="mainComponent">
            <!-- absolute component -->
            <p class="companyName">Complete Signup</p>
            <p class="register">{{ email }}</p>

            <div :class="formFrameClass">
                <div class="form">
                    <div class="nameSection">
                        <div class="name">
                            <p :class="nameTextClass">First Name<span class='text-red'>*</span></p>
                            <input type="text" class="nameInput" placeholder="Enter your first name" v-model="firstName"
                                @input="updateFirstName">
                        </div>
                        <div class="name">
                            <p :class="nameTextClass">Last Name<span class='text-red'>*</span></p>
                            <input type="text" class="nameInput" placeholder="Enter your last name" v-model="lastName"
                                @input="updateLastName">
                        </div>
                    </div>
                    <div class="userInfo">
                        <p :class="usernameClass">A Username that you would like to known with<span
                                class='text-red'>*</span></p>
                        <input type="text" class="usernameInput" placeholder="Enter your username" v-model="username"
                            @input="updateUsername">
                    </div>

                    <button class="application" :disabled="!canSignup" @click="signup">Complete application ▶</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios'
import {SERVER_URL} from '../../constant.js'

export default {
    data() {
        return {
            email: localStorage.getItem('email'),
            firstName: "",
            lastName: "",
            username: "",
            canSignup: false,
            isDark: JSON.parse(localStorage.getItem("themeIsDark")) || this.getTheme,
        }
    },
    computed: {
        getTheme() {
            return this.$store.getters.getTheme;
        },
        pageClass() {
            return this.isDark ? "page-dark" : "page";
        },
        formFrameClass() {
            return this.isDark ? "formFrame-dark" : "formFrame";
        },
        notifyClass() {
            return this.isDark ? "notify-dark" : "notify";
        },
        signinClass() {
            return this.isDark ? "signin-dark" : "signin";
        },
        nameTextClass() {
            return this.isDark ? "nameText-dark" : "nameText";
        },
        usernameClass() {
            return this.isDark ? "username-dark" : "username";
        },
    },
    methods: {
        updateFirstName(e) {
            this.firstName = e.target.value;
            this.setCanSignup()
        },
        updateLastName(e) {
            this.lastName = e.target.value;
            this.setCanSignup()
        },
        updateUsername(e) {
            this.username = e.target.value;
            this.setCanSignup()
        },
        setCanSignup() {
            if (this.firstName.trim() != '' && this.lastName.trim() != '' && this.username.trim() != '') {
                this.canSignup = true;
            } else {
                this.canSignup = false;
            }
        },
        async signup() {
            const url = `${SERVER_URL}/api/registerUserUsingOTP`
            const data = {
                fullName: this.firstName.trim() + " " + this.lastName.trim(),
                username: this.username.trim(),
                email: localStorage.getItem('email'),
            }
            await axios.post(url, data)
                .then((response) => {
                    this.$toast(response.data.message,{
                        // For the actual toast, including different toast types:
                        toastClassName: "my-custom-toast-class",

                        // For the toast body when using strings or a custom component as content
                        bodyClassName: ["custom-class-1", "custom-class-2"]
                    })
                    localStorage.setItem('isLogin', true)
                    this.$router.push('/')
                })
                .catch((error) => {
                    console.log(error)
                    this.$toast(error.response.data.message,{
                        // For the actual toast, including different toast types:
                        toastClassName: "my-custom-toast-class",

                        // For the toast body when using strings or a custom component as content
                        bodyClassName: ["custom-class-1", "custom-class-2"]
                    })
                    localStorage.setItem('isLogin', false)
                    this.$router.push('/otp')
                })
        }
    },
    watch: {
        getTheme(newTheme) {
            this.isDark = newTheme;
        }
    }
}
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.page {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.page-dark {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
}

.mainComponent {
    width: 80%;
    height: 80%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.companyName {
    position: absolute;
    top: -3rem;
    left: 0;
    color: var(--Neutral-Var-40, #646866);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
}

.companyName-dark {
    position: absolute;
    top: -3rem;
    left: 0;
    color: #fff;
    font-family: Montserrat;
    font-size: 1.26075rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.00475rem;
}

.register {
    color: var(--Neutral-Var-40, #646866);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    position: absolute;
    top: -3rem;
    right: 0;
}

.formFrame {
    height: 80%;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 1rem;
    box-shadow: 0px -15px 100px 15px rgba(7, 24, 20, 0.08);
    padding: 20px;
    background-color: #fbfefd;
}

.formFrame-dark {
    height: 80%;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 1rem;
    box-shadow: 0px -15px 100px 15px #602A9A;
    padding-bottom: 20px;
    background-color: #18162099;
    position: relative;
}


.form {
    height: calc(100% - 5rem);
    width: 100%;
    padding: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.nameSection {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-top: 2rem;
}

.nameText {
    color: #000;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
}

.nameText-dark {
    color: #fff;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
}

.nameInput {
    display: flex;
    width: 90%;
    padding-left: 10px;
    height: 40px;
    align-items: flex-start;
    gap: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid #DEF1EC;
    background: var(--white-prim-99, #FFF);
}

.userInfo {
    margin-top: 10px;
}

.username {
    color: #000;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
}

.username-dark {
    color: #fff;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
}

.usernameInput {
    display: flex;
    justify-content: center;
    width: 97%;
    height: 40px;
    align-items: flex-start;
    gap: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid #DEF1EC;
    background: var(--white-prim-99, #FFF);
}

.application {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--Neutral-Var-40, #646866);
    width: 100%;
    padding: 0.8125rem 7.75rem;
    border-radius: 0.5rem;
    text-wrap: nowrap;
    text-align: center;
    border: 1px solid var(--Neutral-Var-40, #646866);
    cursor: pointer;
    margin-top: 20px;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    background-color: transparent;
}

.application:hover {
    background-color: black;
    color: white;
}

.text-red {
    color: red;
}

@media (max-width: 900px) {
    .formFrame {
        height: 80%;
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 500px) {
    .formFrame {
        height: 80%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .formFrame-dark {
        height: 80%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .form {
        height: 100%;
        width: 100%;
        padding: 5rem;
    }

    .mainComponent {
        margin-left: 2px;
        margin-right: 2px;
        width: 95%;
        height: 80%;
        position: relative;
        display: flex;
        justify-content: center;
    }

    .companyName {
        font-size: 1rem;
    }

    .register {
        font-size: 1rem;
    }
}
</style>