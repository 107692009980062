var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.getIsSymbolSearchOpen
    ? _c("div", { staticClass: "page" }, [
        _c("div", { class: _vm.mainClass }, [
          _c(
            "button",
            { staticClass: "cross", on: { click: _vm.closeSymbolSearch } },
            [_vm._v("X")]
          ),
          _vm._v(" "),
          _c("h1", [_vm._v("Symbol Search")]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "search" }, [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "18",
                  height: "18",
                  fill: "none",
                },
              },
              [
                _c("path", {
                  attrs: {
                    stroke: "currentColor",
                    d: "M12.4 12.5a7 7 0 1 0-4.9 2 7 7 0 0 0 4.9-2zm0 0l5.101 5",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.symbol,
                  expression: "symbol",
                },
              ],
              ref: "myInput",
              class: _vm.inputClass,
              attrs: { type: "text", placeholder: "Search Symbol" },
              domProps: { value: _vm.symbol },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.symbol = $event.target.value
                  },
                  _vm.updateSearch,
                ],
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "tools" }, [
              _c(
                "button",
                {
                  class: _vm.toolClass,
                  on: { click: () => _vm.mathSymbol("/") },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 13 13",
                        width: "13",
                        height: "13",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          fill: "none",
                          stroke: "currentColor",
                          "stroke-linecap": "square",
                          d: "M2.5 6.5h9",
                        },
                      }),
                      _vm._v(" "),
                      _c("circle", {
                        attrs: {
                          fill: "currentColor",
                          cx: "7",
                          cy: "3",
                          r: "1",
                        },
                      }),
                      _vm._v(" "),
                      _c("circle", {
                        attrs: {
                          fill: "currentColor",
                          cx: "7",
                          cy: "10",
                          r: "1",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  class: _vm.toolClass,
                  on: { click: () => _vm.mathSymbol("-") },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 13 13",
                        width: "13",
                        height: "13",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          fill: "none",
                          stroke: "currentColor",
                          "stroke-linecap": "square",
                          d: "M2.5 6.5h8",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  class: _vm.toolClass,
                  on: { click: () => _vm.mathSymbol("+") },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 13 13",
                        width: "13",
                        height: "13",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          fill: "none",
                          stroke: "currentColor",
                          "stroke-linecap": "square",
                          d: "M2.5 6.5h8m-4-4v8",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  class: _vm.toolClass,
                  on: { click: () => _vm.mathSymbol("*") },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 13 13",
                        width: "13",
                        height: "13",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          fill: "none",
                          stroke: "currentColor",
                          "stroke-linecap": "square",
                          d: "M3 10l7-7M3 3l7 7",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  class: _vm.toolClass,
                  on: { click: () => _vm.mathSymbol("ex") },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 13 13",
                        width: "13",
                        height: "13",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          fill: "none",
                          stroke: "currentColor",
                          "stroke-linecap": "square",
                          d: "M3 7l3.5-3.5L10 7",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  class: _vm.toolClass,
                  on: { click: () => _vm.mathSymbol("1/") },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 13 13",
                        width: "13",
                        height: "13",
                      },
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            fill: "none",
                            "fill-rule": "evenodd",
                            stroke: "currentColor",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              "stroke-linecap": "square",
                              "stroke-linejoin": "round",
                              d: "M3.5 10V2.5L1 5",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              "stroke-linecap": "square",
                              d: "M1.5 10.5h4",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", { attrs: { d: "M8 12l3-11" } }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "symbolSearch" }, [
            _c(
              "div",
              { staticClass: "filters" },
              _vm._l(_vm.filters, function (filter, i) {
                return _c(
                  "button",
                  {
                    key: i,
                    class: {
                      selectedOption: filter.type === _vm.option,
                      filter: filter.type !== _vm.option,
                    },
                    on: { click: () => _vm.selectOption(i, filter) },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(filter.type) +
                        "\n                "
                    ),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "subFilters" },
              _vm._l(_vm.subFilters, function (subFilter, i) {
                return _c(
                  "button",
                  {
                    key: i,
                    class: _vm.subFilterClass,
                    on: { click: () => _vm.selectSubOption(i) },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(subFilter) +
                        "\n                    "
                    ),
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 18 18",
                          width: "18",
                          height: "18",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            fill: "currentColor",
                            d: "M3.92 7.83L9 12.29l5.08-4.46-1-1.13L9 10.29l-4.09-3.6-.99 1.14Z",
                          },
                        }),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm.info.length > 0
              ? _c("table", { staticClass: "items" }, [
                  _c(
                    "tbody",
                    _vm._l(_vm.allSymbols, function (item) {
                      return _c(
                        "tr",
                        {
                          key: item.symbol,
                          on: { click: () => _vm.selectSymbol(item.symbol) },
                        },
                        [
                          _c("td", [
                            _c("div", { staticClass: "tdSym" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.showColor,
                                      expression: "showColor",
                                    },
                                  ],
                                  staticClass: "colorPattel",
                                },
                                _vm._l(_vm.colors, function (color, index) {
                                  return _c("span", { key: index }, [
                                    _c("span", {
                                      staticClass: "colorCircle",
                                      style: { backgroundColor: color },
                                      on: {
                                        click: function ($event) {
                                          return _vm.colorSlected(
                                            color,
                                            item.symbol
                                          )
                                        },
                                      },
                                    }),
                                  ])
                                }),
                                0
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "addWatchlist",
                                  on: { click: _vm.showColors },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        fill: "gray",
                                        xmlns: "http://www.w3.org/2000/svg",
                                        viewBox: "0 0 14 12",
                                        width: "14",
                                        height: "12",
                                        focusable: "false",
                                        preserveAspectRatio: "none",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: { d: "M14 12l-4-6 4-6H0v12z" },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "tdImg" }, [
                                _vm._v(_vm._s(item.symbol[0])),
                              ]),
                              _vm._v(" "),
                              _c("p", [_vm._v(_vm._s(item.symbol))]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("p", [_vm._v(_vm._s(item.companyName))]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            _vm._l(item.color, function (color) {
                              return _c("div", {
                                key: color,
                                staticClass: "color-circle",
                                style: { backgroundColor: color },
                              })
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c("td", { staticClass: "tdCountry" }, [
                            _c("p", [_vm._v(_vm._s(item.type))]),
                            _vm._v(" "),
                            _c("p", [_vm._v(_vm._s(item.exchange))]),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "roudedImg",
                              attrs: {
                                loading: "lazy",
                                src: item.countryImage,
                                alt: "...",
                              },
                            }),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              : _c("div", [
                  _c("p", { staticClass: "noResults" }, [
                    _vm._v("No results found"),
                  ]),
                ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }