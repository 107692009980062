<template>
  <!-- if showbar is true  -->
    <div v-if="showBar" :class="barClass" :style="{ width: width + 'px'}">
      <div class="resizeHandle" @mousedown="onMouseDown"></div>
      <div class="toolBar">
        <div class="toolbarItem" @click="handleInfoBar">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" width="44" height="44">
            <path fill="currentColor"
              d="M13 11h18a2 2 0 0 1 2 2v17a2 2 0 0 1-2 2H13a2 2 0 0 1-2-2V13c0-1.1.9-2 2-2Zm18-1H13a3 3 0 0 0-3 3v17a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3V13a3 3 0 0 0-3-3Zm-2 11H15v1h14v-1Zm-14-5h14v1H15v-1Zm14 10H15v1h14v-1Z">
            </path>
          </svg>
        </div>
        <!-- <div class="toolbarItem" > -->
          <FeedBack></FeedBack>
        <!-- </div> -->
      </div>
      <watchlist/>
    </div>
    <!-- if showbar is false -->
    <div v-else class="noRightToolBar"></div>
  </template>
  
  <script>
  import Data from '../../data/data.json';
  import FeedBack from './FeedBack.vue';
  import Watchlist from './Watchlist.vue';
  export default {
    name: "RightToolBar",
    components: { FeedBack, Watchlist },
    data() {
      return {
        width: 0,
        isResizing: false,
        startX: 0,
        startWidth: 0,
        isInfoOpen: false,
        showBar: JSON.parse(localStorage.getItem("showRightBar")) || this.getShowBar,
  
        // For bottom bar
        bottomBarHeight: 0,
        isResizingBottombar: false,
        startY: 0,
        startHeight: 0,
        bottomBarInfo: null,
  
        // For watchlist
        selectedSymbol: "AAPL",
        watchlist: Data.Stocks,
  
        // For categories
        isFuturesOpen: false,
        futures: Data.futures,
        isForexOpen: false,
        forex: Data.forex,
        isCryptoOpen: false,
        crypto: Data.crypto,

        //for theme
        isDark: JSON.parse(localStorage.getItem("themeIsDark")) || this.getTheme,
      };
    },
    computed: {
      getWidth() {
        return this.$store.getters.rightToolBarWidth;
      },
      getIsInfoOpen() {
        return this.$store.getters.isInfoOpen;
      },
      getBottomBarHeight() {
        return this.$store.getters.getBottomBarHeight;
      },
      getShowBar(){
        return this.$store.getters.getShowBar;
      },
      getTheme() {
        return this.$store.getters.getTheme;
      },
      barClass() {
        return this.isDark ? "bar-dark" : "bar";
      },
      headerBtnClass() {
        return this.isDark ? "headerBtn-dark" : "headerBtn";
      },
      bottomBarClass() {
        return this.isDark ? "bottomBar-dark" : "bottomBar";
      },

    },
    methods: {
      onMouseDown(event) {
        this.isResizing = true;
        this.startX = event.clientX;
        this.startWidth = this.width;
        document.addEventListener("mousemove", this.onMouseMove);
        document.addEventListener("mouseup", this.onMouseUp);
        event.preventDefault(); // Prevent text selection
      },
      onMouseMove(event) {
        if (this.isResizing) {
          const delta = this.startX - event.clientX;
          this.width = this.startWidth + delta;
          if (this.width < 56) {
            // Minimum width
            this.width = 56;
          } else if (this.width < 256) {
            this.$store.commit("toggleInfoOpen", false);
            this.width = 56;
          } else if (this.width > 256 && this.width < window.innerWidth * 0.6) {
            this.$store.commit("toggleInfoOpen", true);
          } else if (this.width > window.innerWidth * 0.6) {
            // Maximum width is 60% of innerWidth
            this.width = window.innerWidth * 0.6;
          }
          this.$store.commit("updateRightToolBarWidth", this.width);
        }
      },
      onMouseUp() {
        if (this.isResizing) {
          this.isResizing = false;
          document.removeEventListener("mousemove", this.onMouseMove);
          document.removeEventListener("mouseup", this.onMouseUp);
        }
      },
      onMouseDownBottomBar(event) {
        this.isResizingBottombar = true;
        this.startY = event.clientY;
        this.startHeight = this.bottomBarHeight;
        document.addEventListener("mousemove", this.onMouseMoveBottomBar);
        document.addEventListener("mouseup", this.onMouseUpBottomBar);
        event.preventDefault(); // Prevent text selection
      },
      onMouseMoveBottomBar(event) {
        if (this.isResizingBottombar) {
          const delta = this.startY - event.clientY;
          this.bottomBarHeight = this.startHeight + delta;
          if (this.bottomBarHeight < 35) {
            // Minimum height
            this.bottomBarHeight = 35;
          }
          this.$store.commit("updateBottomHeight", this.bottomBarHeight);
        }
      },
      onMouseUpBottomBar() {
        if (this.isResizingBottombar) {
          this.isResizingBottombar = false;
          document.removeEventListener("mousemove", this.onMouseMoveBottomBar);
          document.removeEventListener("mouseup", this.onMouseUpBottomBar);
        }
      },
      handleInfoBar() {
        if (this.$store.getters.isInfoOpen) {
          this.$store.commit("toggleInfoOpen", false);
          this.width = 56;
          this.$store.commit("updateRightToolBarWidth", this.width);
        } else {
          this.$store.commit("toggleInfoOpen", true);
          this.width = 356;
          this.$store.commit("updateRightToolBarWidth", this.width);
        }
      },
      selectSymbol(symbol) {
        this.selectedSymbol = symbol;
        this.InfoFromSymbol(symbol)
      },
      ToggleFutures() {
        this.isFuturesOpen = !this.isFuturesOpen;
      },
      ToggleForex() {
        this.isForexOpen = !this.isForexOpen;
      },
      ToggleCrypto() {
        this.isCryptoOpen = !this.isCryptoOpen;
      },
      InfoFromSymbol(name) {
        Data.watchlist.map((list) => {
          if (list.symbol == name) {
            this.bottomBarInfo = list;
          }
        })
        Data.futures.map((list) => {
          if (list.symbol == name) {
            this.bottomBarInfo = list;
          }
        })
        Data.forex.map((list) => {
          if (list.symbol == name) {
            this.bottomBarInfo = list;
          }
        })
        Data.crypto.map((list) => {
          if (list.symbol == name) {
            this.bottomBarInfo = list;
          }
        })
        console.log(this.bottomBarInfo)
      }
    },
    mounted() {
      this.width = this.getWidth;
      this.bottomBarHeight = this.getBottomBarHeight;
    },
    watch: {
      getWidth(newWidth) {
        this.width = newWidth;
      },
      getBottomBarHeight(newHeight) {
        this.bottomBarHeight = newHeight;
      },
      getShowBar(bool) {
        this.showBar = bool;
      },
      getTheme(newTheme) {
        this.isDark = newTheme;
      },
    },
  };
  </script>
  
  <style scoped>
  .watchlist {
    width: calc(100% - 56px);
    margin-right: 56px;
    font-family: Arial, sans-serif;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid #ddd;
  }
  
  .title {
    font-size: 15px;
    padding: 8px;
    border-radius: 10px;
    font-weight: bold;
  }
  
  .title:hover {
    cursor: pointer;
    background-color: #76878319;
  }
  
  .header-icons {
    display: flex;
    gap: 10px;
  }
  
  .icon {
    cursor: pointer;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .tdSym {
    display: flex;
    align-items: center;
    gap: 2px
  }
  
  .tdImg {
    background-color: #e0e3eb;
    border-radius: 100%;
    padding: 5px;
    font-weight: bold;
    color: white;
  }
  
  tbody {
    width: 100%;
  }
  
  th {
    font-weight: bold;
    color: #888;
  }
  
  tr:hover {
    background-color: #f5f5f5;
  }
  
  .selected {
    border-left: 4px solid blue;
  }
  
  .positive {
    color: green;
  }
  
  .negative {
    color: red;
  }
  
  .categories {
    display: flex;
    flex-direction: column;
  }
  
  .category {
    color: #888;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .category:hover {
    color: black;
  }
  
  .bar {
    border-top:  1px solid rgba(55, 50, 50, 0.273);
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    margin-top: 42px;
    display: flex;
    background-color: white;
    z-index: 1001;
  }
  .bar-dark {
    border-top:  1px solid rgba(55, 50, 50, 0.273);
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    margin-top: 42px;
    display: flex;
    background-color: #1e1e1e;
    z-index: 1001;
  }
  
  .toolBar {
    position: absolute;
    top: 0;
    right: 0;
    width: 56px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 1px solid rgba(55, 50, 50, 0.273);
    z-index: 10;
  }
  
  .toolbarItem {
    margin-top: 15px;
    width: 45px;
    height: 45px;
    border-radius: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .toolbarItem:hover {
    background-color: #76878319;
    cursor: pointer;
  }
  
  .resizeHandle {
    position: absolute;
    top: 0;
    left: -1px;
    width: 3px;
    height: 100%;
    cursor: ew-resize;
    z-index: 12;
    background-color: #ccc;
  }
  
  .infoBar {
    height: calc(100% - 70px);
    overflow-y: scroll;
    width: 200px;
    margin-top: 10px;
  }
  
  .header {
    height: 5%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .headerBtn {
    padding: 1px 10px;
    border-radius: 10%;
    border: none;
    background-color: transparent;
    outline: none;
    height: 80%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .headerBtn-dark {
    padding: 1px 10px;
    border-radius: 10%;
    border: none;
    background-color: transparent;
    outline: none;
    height: 80%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ccc;
  }
  
  .bottomBarHeaderRight {
    display: flex;
    align-items: center;
  }
  
  .headerBtn:hover {
    background-color: #76878319;
  }
  .headerBtn-dark:hover {
    background-color: #76878319;
  }
  
  .toggleBtn {
    height: 10px;
    width: 10px;
  }
  
  .left {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  
  .leftImg {
    height: 30%;
  }
  
  .leftImg:hover {
    height: 30%;
    animation: downArrow 1s ease-in-out forwards;
  }
  
  .bottomBar {
    position: absolute;
    bottom: 35px;
    height: 35px;
    width: calc(100% - 56px);
    background-color: #fff;
    margin-right: 56px;
  }
  .bottomBar-dark {
    position: absolute;
    bottom: 35px;
    height: 35px;
    width: calc(100% - 56px);
    background-color: #1e1e1e;
    margin-right: 56px;
  }
  
  .resizeHandleBottom {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 3px;
    cursor: ns-resize;
    z-index: 5;
    background-color: #ccc;
  }
  
  .bottomBarHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 35px;
  }
  .noRightToolBar{
    display: none;
  }
  
  @keyframes downArrow {
    0% {
      padding-top: 0px;
    }
  
    50% {
      padding-top: 5px;
    }
  
    100% {
      padding-top: 10px;
    }
  }
  </style>
  