<script>
// Line drawing tool
// TODO: make an angle-snap when "Shift" is pressed

import Overlay from "../../mixins/overlay.js";
import Tool from "../../mixins/tool.js";
import Icons from "../../stuff/icons.json";
import Pin from "../primitives/pin.js";
import Seg from "../primitives/seg.js";
import Line from "../primitives/line.js";
import Ray from "../primitives/ray.js";
import PriceLine from "../primitives/priceline.js";

export default {
  name: "LineTool",
  mixins: [Overlay, Tool],
  // props: ["newIsLocked"],
  // watch: {
  //   newIsLocked() {
  //     this.isLocked = this.newIsLocked;
  //     this.isLockTool(this.isLocked);
  //   },
  // },
  methods: {
    meta_info() {
      return { author: "C451", version: "1.1.0" };
    },
    tool() {
      return {
        group: "Lines",
        icon: Icons["segment.svg"],
        type: "Segment",
        hint: "This hint will be shown on hover",
        data: [],
        settings: {},
        mods: {
          Extended: {
            settings: { extended: true },
            icon: Icons["extendedline.svg"],
          },
          Ray: {
            settings: { ray: true },
            icon: Icons["ray.svg"],
          },
          PriceLine: {
            settings: { price_line: true },
            icon: Icons["price_line.svg"],
          },
        },
      };
    },
    init() {
      // this.isLocked = this.newIsLocked;
      // this.isLockTool(this.isLocked);
      // First pin is settled at the mouse position
      this.pins.push(new Pin(this, "p1"));
      if (this.sett.price_line) {
        this.pins.push(
          new Pin(this, "p2", {
            state: "nodragging",
          })
        );
        this.set_state("finished");
        this.$emit("drawing-mode-off");
      } else {
        this.pins.push(
          new Pin(this, "p2", {
            state: "tracking",
          })
        );
        // if (this.newIsLocked) {
        //   this.pins[1].on("nodragging", () => {
        //     // Call when current tool drawing is finished
        //     // (Optionally) reset the mode back to 'Cursor'

        //     this.set_state("finished");
        //     this.$emit("drawing-mode-off");
        //   });
        // } else {
        this.pins[1].on("settled", () => {
          // Call when current tool drawing is finished
          // (Optionally) reset the mode back to 'Cursor'

          this.set_state("finished");
          this.$emit("drawing-mode-off");
        });
      }
    },
    draw(ctx) {
      if (!this.p1 || (!this.p2 && !this.sett.price_line)) return;

      ctx.lineWidth = this.line_width;
      ctx.strokeStyle = this.color;
      ctx.beginPath();

      if (this.sett.ray) {
        new Ray(this, ctx).draw(this.p1, this.p2);
      } else if (this.sett.extended) {
        new Line(this, ctx).draw(this.p1, this.p2);
      } else if (this.sett.price_line) {
        new PriceLine(this, ctx).draw(this.p1);
      } else {
        new Seg(this, ctx).draw(this.p1, this.p2);
      }

      ctx.stroke();
      this.render_pins(ctx);
    },
    use_for() {
      return ["LineTool"];
    },
    data_colors() {
      return [this.color];
    },
  },
  computed: {
    sett() {
      return this.$props.settings;
    },
    p1() {
      return this.$props.settings.p1;
    },
    p2() {
      return this.$props.settings.p2;
    },
    line_width() {
      return this.sett.lineWidth || 0.9;
    },
    color() {
      return this.sett.color || "#42b28a";
    },
  },
  data() {
    return {};
  },
};
</script>
