var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("AccountSettingsPage", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.accountSettingsPage,
            expression: "accountSettingsPage",
          },
        ],
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "tradingVueRef",
          staticClass: "trading-vue",
          style: {
            color: _vm.chart_props.colors.text,
            font: _vm.font_comp,
            width: _vm.width + "px",
            height: _vm.height + "px",
          },
          attrs: { id: _vm.id },
          on: {
            mousedown: _vm.mousedown,
            mouseleave: _vm.mouseleave,
            click: _vm.handleClick,
          },
        },
        [
          _c("Header", {
            attrs: {
              "charts-tf": _vm.chartsTf,
              "replay-selected": _vm.replaySelected,
              "replay-active": _vm.replayActive,
              TFfunction: _vm.handleTFfunction,
            },
            on: {
              passShowSreenShot: _vm.passShowSreenShot,
              fullscreen: _vm.fullscreen,
            },
          }),
          _vm._v(" "),
          _vm.toolbar
            ? _c(
                "toolbar",
                _vm._b(
                  {
                    ref: "toolbar",
                    attrs: { config: _vm.chart_config },
                    on: { "custom-event": _vm.custom_event },
                  },
                  "toolbar",
                  _vm.chart_props,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.controllers.length
            ? _c("widgets", {
                ref: "widgets",
                attrs: {
                  map: _vm.ws,
                  width: _vm.width,
                  height: _vm.height,
                  tv: this,
                  dc: _vm.data,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "chart",
            _vm._b(
              {
                key: _vm.reset,
                ref: "chart",
                attrs: {
                  "replay-selected": _vm.replaySelected,
                  "replay-stopped": _vm.replayStopped,
                  tv_id: _vm.id,
                  config: _vm.chart_config,
                  "set-replay-active": _vm.setReplayActive,
                  tradingVueRef: this.$refs.tradingVueRef,
                },
                on: {
                  "custom-event": _vm.custom_event,
                  "range-changed": _vm.range_changed,
                  "legend-button-click": _vm.legend_button,
                },
              },
              "chart",
              _vm.chart_props,
              false
            )
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "tvjs-drift" } },
            [
              _vm.tip
                ? _c("the-tip", {
                    attrs: { data: _vm.tip },
                    on: {
                      "remove-me": function ($event) {
                        _vm.tip = null
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("RightToolBar"),
          _vm._v(" "),
          _c("SymbolSearch"),
          _vm._v(" "),
          _c("alert"),
          _vm._v(" "),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.JoinUsPopup,
                expression: "JoinUsPopup",
              },
            ],
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.getisWatchlistOpen,
                  expression: "getisWatchlistOpen",
                },
              ],
            },
            [_c("WatchlistPopup")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }