<template>
    <div class="page" @click="handlePageClick">
      <div :class="mainClass" ref="mainContent">
        <!-- Close Button -->
        <button class="cross" @click="closePage">X</button>
  
        <!-- Title -->
        <h1 :class="titleClass">Watchlist</h1>
        <hr>
  
        <!-- Search Bar -->
        <div class="search">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
            <path stroke="currentColor" d="M12.4 12.5a7 7 0 1 0-4.9 2 7 7 0 0 0 4.9-2zm0 0l5.101 5"></path>
          </svg>
          <input ref="myInput" type="text" :class="inputClass" v-model="search" @input="updateSearch"
            placeholder="Search List">
        </div>
        <hr>
  
        <div class="container">
          <!-- sidebar -->
          <div class="sidebar">
            <div class="sidebarObject">
              <span class="showAlways">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                  <path fill="currentColor" d="M9 8.5h10V7H9v1.5ZM9 12.5h10V11H9v1.5ZM19 16.5H9V15h10v1.5Z"></path>
                  <path fill="currentColor" fill-rule="evenodd"
                    d="m14 21-9 4V3h18v22l-9-4Zm-7.5 1.7V4.5h15v18.2L14 19.35l-7.5 3.33Z"></path>
                </svg>
              </span>
              <span class="HiddenForSmallScreen">My Watchlists</span>
            </div>
            <div class="sidebarObject newList" @click="ToggleCreateNewListPage">
              <span class="showAlways">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                  <path fill="currentColor" d="M16.59 22.21 14 21.1 5 25V3h18v10h-1V4H6v19.48L14 20l3 1.3-.41.91Z"></path>
                  <path fill="currentColor"
                    d="M22 19v-4h1v4h4v1h-4v4h-1v-4h-4v-1h4ZM9 8h10V7H9v1ZM9 12h10v-1H9v1ZM17 16H9v-1h8v1Z">
                  </path>
                </svg>
              </span>
              <span class="HiddenForSmallScreen">Create new list</span>
            </div>
          </div>
          <!-- main -->
          <div class="Body">
            <table class="listTable bodyHeader">
              <thead>
                <tr>
                  <th>FLAGGED LISTS</th>
                  <th>SYMBOLS</th>
                </tr>
              </thead>
              <tbody>
                <tr class="trClass" v-for="(item, index) in filteredWatchlist" :key="index" @click="selectWatchlist(item)">
                  <td class="watchlistName">
                    <svg :style="{ fill: item.color }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12" width="14"
                      height="12" focusable="false" preserveAspectRatio="none">
                      <path d="M14 12l-4-6 4-6H0v12z"></path>
                    </svg>
                    <p :class="themeTextChange">
                      {{ item.name }}
                    </p>
  
                    <input type="text" value="sankalp">
  
                    <span class="edit" @click.stop="toggleEdit(item)">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                        <path fill="currentColor"
                          d="M15 6H7.5C6.67 6 6 6.67 6 7.5v13c0 .83.67 1.5 1.5 1.5h13c.83 0 1.5-.67 1.5-1.5V16h1v4.5a2.5 2.5 0 0 1-2.5 2.5h-13A2.5 2.5 0 0 1 5 20.5v-13A2.5 2.5 0 0 1 7.5 5H15v1Z">
                        </path>
                        <path fill="currentColor"
                          d="M22.41 5.7a2 2 0 0 0-2.82 0L11 14.3V18h3.7l8.6-8.59a2 2 0 0 0 0-2.82l-.89-.88Zm-2.12.71a1 1 0 0 1 1.42 0l.88.88a1 1 0 0 1 0 1.42l-.59.58L19.7 7l.6-.59Zm1 3.59-7 7H12v-2.3l7-7 2.3 2.3Z">
                        </path>
                      </svg>
                    </span>
                  </td>
                  <td :class="themeTextChange">
                    {{ item.symbols.length }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
  
        <!-- create new list page -->
        <div v-show="createNewListPage" :class="createNewListPageClass" >
          <span class="cross" @click="ToggleCreateNewListPage">X</span>
          <div :class="mainComponentClass">
            <h2 :class="titleTextClass">Create list</h2>
            <div >
              <div class="inputContainer">
                <p>Create New Watchlist</p>
                <input :class="ListInputClass" type="text" v-model="newListName" placeholder="Enter List name"
                  @input="updateListName">
    
                <p class="ColorText">Choose a Color for your Watchlist</p>
                <select name="selectColor" id="selectColor" @change="selectColor">
                  <option :value="color" v-for="color in colors" :key="color">
                    <span class="colorBlock" :style="{ backgroundColor: color }"></span>
                    <p>{{ color }}</p>
                  </option>
                </select>
              </div>
    
              <button class="save" @click="createList">Create</button>
    
            </div>

          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { SERVER_URL } from '../constant.js'
  
  export default {
    name: 'WatchlistPopup',
    data() {
      return {
        search: "",
        isDark: JSON.parse(localStorage.getItem("themeIsDark")) || this.getTheme,
        watchlist: [],
        filteredWatchlist: [],
        selectedWatchlist: {},
        createNewListPage: false,
        colors: ['red', 'blue', 'green', 'yellow', 'purple', 'Maroon', 'Olive', 'DarkGreen', 'white', 'Gray', 'Navy', 'orange', 'Brown', 'Chartreuse', 'Chocolate', 'Crimson', 'DarkBlue', 'DarkCyan', 'DarkGoldenRod', 'black'],
        newListName: "",
        selectedColor: "" || "red",
      
        socket: {},
      }
    },
    computed: {
      getTheme() {
        return this.$store.getters.getTheme;
      },
      mainClass() {
        return this.isDark ? "main-dark" : "main";
      },
      inputClass() {
        return this.isDark ? "input-dark" : "input";
      },
      titleClass() {
        return this.isDark ? "title-dark" : "title";
      },
      themeTextChange() {
        return !this.isDark ? "LightThemetext" : "DarkThemeText"
      },
      titleTextClass() {
        return this.isDark ? "title-text-dark" : "title-text"
      },
      createNewListPageClass() {
        return this.isDark ? "createNewListPage-dark" : "createNewListPage"
      },
      ListInputClass() {
        return this.isDark ? "listInput-dark" : "listInput"
      },
      mainComponentClass(){
        return this.isDark ? "mainComponent-dark" : "mainComponent"
      },
      // get socket from store
      getSocketConnection() {
        return this.$store.getters.getSocketConnection;
      }
    },
    async mounted() {
      // console.log('WatchlistPopup mounted');
      // this component mounts only when user is login
      const email = localStorage.getItem('email')
      // console.log(email)
  
      //send email to backend and get all watchlist of user
      const url = `${SERVER_URL}/api/watchlist/getWatchlist`
      const data = {
        email: email
      }
      await axios.post(url, data)
        .then((res) => {
          // console.log(res.data.watchlist)
          this.watchlist = res.data.watchlist
          this.filteredWatchlist = this.watchlist
        })
        .catch((err) => {
          console.log(err)
        })
  
      // socket code
      this.socket = this.getSocketConnection
      this.socket.on('getAllWatchlist', (data) => {
        // console.log("dankalp : ", data)
        this.watchlist = data.watchlist
        this.filteredWatchlist = this.watchlist
      })
  
    },
    methods: {
      toggleEdit(item){
        console.log("editing")
      },
      createList() {
        if (this.newListName.trim() == "") {
          this.$toast("Please entre name", {
            toastClassName: "my-custom-toast-class",
            bodyClassName: ["custom-class-1", "custom-class-2"]
          })
          return
        }
        const url = `${SERVER_URL}/api/watchlist/CreateList`
        const data = {
          email: localStorage.getItem('email'),
          name: this.newListName,
          color: this.selectedColor
        }
        // console.log(data)
        axios.post(url, data)
          .then(response => {
  
            this.socket.emit('newListCreated', (data))
            
            this.$toast('list created', {
              toastClassName: "my-custom-toast-class",
              bodyClassName: ["custom-class-1", "custom-class-2"]
            })
            this.createNewListPage = false;
          })
          .catch(error => {
            console.error('Error creating list:', error);
            this.$toast(error.response.data.message, {
              toastClassName: "my-custom-toast-class",
              bodyClassName: ["custom-class-1", "custom-class-2"]
            })
          });
      },
      updateListName(e) {
        this.newListName = e.target.value
      },
      ToggleCreateNewListPage() {
        this.createNewListPage = !this.createNewListPage
      },
      selectWatchlist(item) {
        this.selectedWatchlist = item
        this.$store.commit('setSelectedWatchlist', item)
        this.socket.emit('newWatchlistSelected', item)
        this.closePage()
      },
      closePage() {
        this.$store.commit('updateIsWatchlistOpen', false);
        this.$store.commit('updateIsOtherInputSearchOpen', false)
      },
      handlePageClick(event) {
        if (!this.$refs.mainContent.contains(event.target)) {
          this.closePage();
        }
      },
      updateSearch(e) {
        this.search = e.target.value
        // filter this.watchlist based on this.search
        this.filteredWatchlist = this.watchlist.filter(item => item.name.toLowerCase().includes(this.search.toLowerCase()))
        console.log(this.search)
      },
      selectColor(event) {
        this.selectedColor = event.target.value;
        // console.log('Selected Color:', this.selectedColor);
      }
    },
    watch: {
      getTheme(newTheme) {
        this.isDark = newTheme;
      },
    }
  }
  </script>
  
  <style scoped>
  .mainComponent-dark{
    position: relative;
    padding: 10px;
    height: 80%;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */

    box-sizing: border-box;
    border-radius: 1rem;
    box-shadow: 0px -10px 50px 15px rgba(7, 24, 20, 0.1);
    box-shadow: 0px -15px 100px 15px #602A9A;
    padding-bottom: 20px;
  }
  .mainComponent{
    position: relative;
    padding: 10px;
    height: 80%;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */

    box-sizing: border-box;
    border-radius: 1rem;
    box-shadow: 0px -15px 60px 5px rgba(7, 24, 20, 0.08);
    padding-bottom: 20px;
  }
  .editing{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .colorBlock {
    display: inline-block;
    width: 20px;
    height: 20px;
  }
  
  .listInput {
    width: 100%;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 8px;
    outline: none;
    text-transform: uppercase;
    /* border-right: 1px solid #e0e3eb; */
    padding-left: 10px;
    background-color: transparent;
  }
  
  .listInput-dark {
    width: 100%;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 8px;
    outline: none;
    text-transform: uppercase;
    /* border-right: 1px solid #e0e3eb; */
    padding-left: 10px;
    background-color: transparent;
    color: #fff
  }
  
  .title-text {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    position: absolute;
    top: 10px;
    left: 20px;
  }
  
  .title-text-dark {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    position: absolute;
    top: 5px;
    left: 20px;
  }
  
  .LightThemetext {
    color: black;
  }
  
  .DarkThemetext {
    color: white;
  }
  
  .createButton {
    height: 40px;
  
  }
  
  .save {
    outline: none;
    border-radius: 8px;
    background-clip: border-box;
    position: relative;
    background: rgba(140, 69, 255, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    padding: 3px 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: inset 0 0 15px rgba(140, 69, 255);
    /* Inner shadow */
    cursor: pointer;
    color: white;
    margin-top: 50px;
  }
  
  #selectColor {
    background-color: transparent;
    margin-top: 0px;
    color: #ccc;
    border: 1px solid #ccc;
    border-radius: 8px;
    outline: none;
    padding: 5px;
  }
  
  .ColorText {
    margin-top: 20px;
  }
  
  .createNewListPage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 1010;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .createNewListPage-dark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1e1e1e;
    z-index: 1010;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .editName {
    background-color: white;
    width: 100px;
    border: 1px solid #ccc;
    padding: 5px;
    box-sizing: border-box;
  }
  
  .edit:hover {
    cursor: pointer;
    background-color: #88aaa219;
  }
  
  .watchlistName {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .trClass:hover {
    background-color: #ac86eb;
  }
  
  .listTable {
    width: 100%;
    border-collapse: collapse;
  }
  
  .listTable th,
  .listTable td {
    padding: 10px 0px;
    /* border: 1px solid #ccc; */
    text-align: left;
  }
  
  .list {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .bodyHeader {
    color: #ccc;
    font-size: 10px;
  }
  
  .sidebarObject {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    padding: 5px;
  }
  
  .sidebarObject:hover {
    background-color: #ac86eb;
  }
  
  .Body {
    height: 100%;
    width: 70%;
    background-color: transparent;
    font-size: 13px;
    padding: 10px 15px;
    overflow-y: scroll;
  }
  
  .sidebar {
    height: 100%;
    width: 30%;
    background-color: transparent;
    border-right: 1px solid #ccc;
    font-size: 13px;
    position: relative;
  }
  
  .newList {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  
  .container {
    display: flex;
    justify-content: start;
    align-items: center;
    height: 80%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  
  .title {
    color: black;
  }
  
  .title-dark {
    color: white;
  }
  
  .search {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .input {
    width: 70%;
    height: 20px;
    border: none;
    outline: none;
    text-transform: uppercase;
    /* border-right: 1px solid #e0e3eb; */
    padding-right: 10px;
    background-color: transparent;
  }
  
  .input-dark {
    width: 70%;
    height: 20px;
    border: none;
    outline: none;
    text-transform: uppercase;
    /* border-right: 1px solid #e0e3eb; */
    padding-right: 10px;
    background-color: transparent;
    color: #fff;
  }
  
  .page {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(50, 59, 40, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1005;
  }
  
  .main {
    width: 60%;
    height: 70%;
    background-color: white;
    border-radius: 10px;
    position: relative;
    padding: 10px;
    overflow-y: hidden;
  }
  
  .main-dark {
    width: 60%;
    height: 70%;
    background-color: #1e1e1e;
    border-radius: 10px;
    position: relative;
    padding: 10px;
    overflow-y: hidden
  }
  
  .cross {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
    border-radius: 10px;
    color: #aeb0b3;
  }
  
  .cross:hover {
    background-color: #76878319;
  }
  
  
  @media (max-width: 800px) {
    .main {
      width: 80%;
    }
  
    .main-dark {
      width: 80%;
    }
  
    .HiddenForSmallScreen {
      display: none;
    }
  
    .sidebar {
      width: 10%;
    }
  
    .Body {
      height: 100%;
      width: 90%;
    }
  
    .sidebarObject {
      justify-content: center;
    }
  
    .showAlways {
      margin: 0px auto;
    }
  }
  </style>