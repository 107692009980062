<template>
  <div class="copied-link-comp">
    <div class="header">
      <img :src="logo" alt="Logo" class="logo" />
      <div class="btn-comp">
        <button @click="copyLink" class="copy-link-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28 28"
            width="30"
            height="30"
            fill="none"
          >
            <path
              stroke="currentColor"
              d="M19 15l2.5-2.5c1-1 1.5-3.5-.5-5.5s-4.5-1.5-5.5-.5L13 9M10 12l-2.5 2.5c-1 1-1.5 3.5.5 5.5s4.5 1.5 5.5.5L16 18M17 11l-5 5"
            ></path>
          </svg>
          <p>Copy Link</p>
        </button>

        <button @click="launchTradingVue" class="launch-trading-vue">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28 28"
            width="30"
            height="30"
          >
            <path
              fill="currentColor"
              d="M24.72 9.3a1 1 0 0 1-.03 1.42l-7.3 7a1 1 0 0 1-1.43-.05l-4.71-5.23-6.56 6.28a1 1 0 1 1-1.38-1.44l7.3-7a1 1 0 0 1 1.43.05l4.71 5.23 6.56-6.28a1 1 0 0 1 1.41.03z"
            ></path>
          </svg>
          <p>Launch Tradilent</p>
        </button>
      </div>
    </div>
    <div class="screenshot-div">
      <img class="screenshot" :src="image" alt="Screenshot" v-if="image" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Icons from "../stuff/icons.json";
import { SERVER_URL } from '../constant.js'

export default {
  name: "CopiedLink",
  data() {
    return {
      id: this.$route.params.id,
      image: null,
      logo: null,
    };
  },
  async mounted() {
    console.log(this.id);
    this.logo = Icons["logo.png"];
    try {
      console.log("hello");
      const response = await axios.get(`${SERVER_URL}/api/screenshot/${this.id}`);
      console.log(response.data.data);
      this.image = response.data.data.doc.imageUrl;
    } catch (error) {
      console.error("Error fetching screenshot:", error);
    }
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText(document.location.href);
    },
    launchTradingVue() {
      window.open("/", "_blank");
    },
  },
};
</script>

<style scoped>
.copied-link-comp {
  margin: 0;
  height: 100vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.logo {
  width: 100px;
  height: 100px;
}

.copy-link-btn {
  display: flex;
  align-items: center;
  color: white;
  background-color: black;
  outline-style: none;
  border: none;
  font-size: 15px;
  cursor: pointer;
  border-radius: 5px;
}

.copy-link-btn:hover {
  background-color: #1a1a1a;
}

.copy-link-btn svg {
  margin-right: 5px;
}

.btn-comp {
  margin-top: -20px;
  display: flex;
  gap: 20px;
}

.launch-trading-vue {
  display: flex;
  align-items: center;
  color: white;
  background-color: #2962ff;
  border-radius: 5px;
  outline-style: none;
  border: none;
  font-size: 15px;
  cursor: pointer;
}

.launch-trading-vue:hover {
  background-color: #0039cb;
}

.launch-trading-vue svg {
  margin-right: 5px;
}

.screenshot-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 100%;
  padding: 0 10px;
}

.screenshot {
  max-width: 100%;
  height: 70vh;
}
</style>
