<!-- Listens to native keyboard events,
     propagates to all KeyboardListeners -->

     <script>

     export default {
         name: 'Keyboard',
         created: function () {
             window.addEventListener('keydown', this.keydown)
             window.addEventListener('keyup', this.keyup)
             window.addEventListener('keypress', this.keypress)
             this._listeners = {}
         },
         beforeDestroy: function () {
             window.removeEventListener('keydown', this.keydown)
             window.removeEventListener('keyup', this.keyup)
             window.removeEventListener('keypress', this.keypress)
         },
         render(h) { return h() },
         methods: {
             keydown(event) {
                 const noModifierKeys = !event.getModifierState("Shift") &&
                         !event.getModifierState("Control") &&
                         !event.getModifierState("Alt") &&
                         !event.getModifierState("Meta");
     
                 if(!JSON.parse(localStorage.getItem('isLogin')) && noModifierKeys){
                     this.$store.commit('updateJoinUsPopup', true)
                     return
                 }
     
                 if (!this.getIsOtherInputSearchOpen) {
                     // Open symbol search if an alphabet key is pressed and no modifier keys are active
                     const isAlphabet = /^[A-Za-z]$/.test(event.key);
     
                     if (isAlphabet && noModifierKeys && !this.getIsSymbolSearchOpen) {
                         this.$store.commit("updateIsSymbolSearchOpen", true);
                         this.$store.dispatch('triggerFocusInput');
                     }
                 }
     
                 // Ensure _listeners is defined before iterating
                 if (this._listeners) {
                     for (let id in this._listeners) {
                         let listener = this._listeners[id];
                         if (listener && listener.keydown) {
                             listener.keydown(event);
                         } else {
                             console.warn(`No 'keydown' listener for ${id}`);
                         }
                     }
                 } else {
                     console.warn('No _listeners defined');
                 }
             },
             keyup(event) {
                 for (var id in this._listeners) {
                     let l = this._listeners[id]
                     if (l && l.keyup) {
                         l.keyup(event)
                     } else {
                         console.warn(`No 'keyup' listener for ${id}`)
                     }
                 }
             },
             keypress(event) {
                 for (var id in this._listeners) {
                     let l = this._listeners[id]
                     if (l && l.keypress) {
                         l.keypress(event)
                     } else {
                         console.warn(`No 'keypress' listener for ${id}`)
                     }
                 }
             },
             register(listener) {
                 this._listeners[listener.id] = listener
             },
             remove(listener) {
                 delete this._listeners[listener.id]
             },
         },
         computed: {
             getIsOtherInputSearchOpen() {
                 return this.$store.getters.getIsOtherInputSearchOpen
             }
         }
     }
     
     </script>