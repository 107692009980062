var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showBar
    ? _c(
        "div",
        { class: _vm.barClass, style: { width: _vm.width + "px" } },
        [
          _c("div", {
            staticClass: "resizeHandle",
            on: { mousedown: _vm.onMouseDown },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "toolBar" },
            [
              _c(
                "div",
                {
                  staticClass: "toolbarItem",
                  on: { click: _vm.handleInfoBar },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 44 44",
                        width: "44",
                        height: "44",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          fill: "currentColor",
                          d: "M13 11h18a2 2 0 0 1 2 2v17a2 2 0 0 1-2 2H13a2 2 0 0 1-2-2V13c0-1.1.9-2 2-2Zm18-1H13a3 3 0 0 0-3 3v17a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3V13a3 3 0 0 0-3-3Zm-2 11H15v1h14v-1Zm-14-5h14v1H15v-1Zm14 10H15v1h14v-1Z",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("FeedBack"),
            ],
            1
          ),
          _vm._v(" "),
          _c("watchlist"),
        ],
        1
      )
    : _c("div", { staticClass: "noRightToolBar" })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }