<template>
  <div class="con">
    <div class="item" @click="togglePause" :class="{ disabled }">
      <svg
        v-show="paused === undefined || paused === true"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 28 28"
        width="28"
        height="28"
      >
        <path
          fill="currentColor"
          fill-rule="evenodd"
          d="m10.997 6.93 7.834 6.628a.58.58 0 0 1 0 .88l-7.834 6.627c-.359.303-.897.04-.897-.44V7.37c0-.48.538-.743.897-.44Zm8.53 5.749a1.741 1.741 0 0 1 0 2.637l-7.834 6.628c-1.076.91-2.692.119-2.692-1.319V7.37c0-1.438 1.616-2.23 2.692-1.319l7.834 6.628Z"
        ></path>
      </svg>

      <svg
        v-show="paused === false"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 28 28"
        width="28"
        height="28"
      >
        <path
          fill="currentColor"
          fill-rule="evenodd"
          d="M10 6h2v16h-2V6ZM9 6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V6Zm7 0h2v16h-2V6Zm-1 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V6Z"
        ></path>
      </svg>
    </div>
    <div class="item" @click="increaseSlider">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 28 28"
        width="28"
        height="28"
      >
        <path
          fill="currentColor"
          fill-rule="evenodd"
          d="M20 6v16h1V6h-1Zm-3.908 7.628L9.834 7.996A.5.5 0 0 0 9 8.368v11.264a.5.5 0 0 0 .834.372l6.258-5.632a.5.5 0 0 0 0-.744Zm.67 1.487a1.5 1.5 0 0 0 0-2.23l-6.259-5.632C9.538 6.384 8 7.07 8 8.368v11.264c0 1.299 1.538 1.984 2.503 1.115l6.258-5.632Z"
        ></path>
      </svg>
    </div>
    <div class="slider">
      <vue-slider v-model="value" :min="0.25" :max="3" :interval="0.25" />
    </div>
    <div class="item" @click="stopReplay" :class="{ disabled }">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 28 28"
        width="28"
        height="28"
      >
        <path
          fill="currentColor"
          fill-rule="evenodd"
          d="M18 22V6h1v16h-1ZM8.834 7.996l6.258 5.632a.5.5 0 0 1 0 .744l-6.258 5.632A.5.5 0 0 1 8 19.632V8.368a.5.5 0 0 1 .834-.372Zm6.927 4.89a1.5 1.5 0 0 1 0 2.229l-6.258 5.632C8.538 21.616 7 20.93 7 19.632V8.368C7 7.07 8.538 6.384 9.503 7.253l6.258 5.632ZM21 6v16h1V6h-1Z"
        ></path>
      </svg>
    </div>
    <div class="item" @click="cancelReplay">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 17 17"
        width="17"
        height="17"
        fill="currentColor"
      >
        <path d="m.58 1.42.82-.82 15 15-.82.82z"></path>
        <path d="m.58 15.58 15-15 .82.82-15 15z"></path>
      </svg>
    </div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
export default {
  props: [
    "replayActive",
    "paused",
    "togglePause",
    "cancelReplay",
    "stopReplay",
    "setWait",
    "disabled",
  ],
  components: {
    VueSlider,
  },
  data() {
    return {
      value: 1,
    };
  },

  watch: {
    value(newVal) {
      this.$props.setWait(1 / newVal);
    },
  },

  methods: {
    increaseSlider() {
      if (this.value < 3) this.value += 0.25;
    },
  },
};
</script>

<style>
.con {
  font-size: large;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: default;
}
.item {
  width: 30px;
  height: 30px;
  margin: 3px;
  padding: 3px;
  font-size: clamp(16px, 2.5vw, 20px);
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item:hover {
  background-color: #333;
  color: #fff;
  border-radius: 5px;
}

.slider {
  width: 10vw;
  margin: 3px;
  padding: 3px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
