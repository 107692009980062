var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.headerClass }, [
    _c("img", {
      staticClass: "logo",
      attrs: {
        src: "https://res.cloudinary.com/dzd8l9wym/image/upload/v1722250225/iaennuve3ukfiptsqr1d.png",
        alt: "logo",
      },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "selector-div" }, [
      _c(
        "div",
        {
          staticClass: "selector",
          attrs: { name: "", id: "" },
          on: { click: _vm.openSymbolSearchPopup },
        },
        [_vm._v("\n      TCS\n    ")]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "divider-1" }),
    _vm._v(" "),
    _c("div", { staticClass: "scrollable-content" }, [
      _c("div", { staticClass: "feature" }, [
        _c(
          "div",
          { staticClass: "timeframe", class: { disabled: _vm.replay } },
          [_c("TFSelector", { attrs: { TFfunction: _vm.handleTFfunction } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "divider-2" }),
        _vm._v(" "),
        _c("div", { staticClass: "alert-box" }, [
          _c(
            "svg",
            {
              attrs: {
                width: "20",
                height: "20",
                stroke: "currentColor",
                "stroke-width": "0.04",
                viewBox: "0 0 0.63 0.99",
              },
            },
            [
              _c("path", {
                attrs: { fill: "none", d: "M.52.02v.95M.11.02v.95" },
              }),
              _vm._v(" "),
              _c("rect", {
                attrs: {
                  x: ".02",
                  y: ".16",
                  width: ".19",
                  height: ".5",
                  rx: ".02",
                  ry: ".07",
                  fill: "currentColor",
                },
              }),
              _vm._v(" "),
              _c("rect", {
                attrs: {
                  x: ".42",
                  y: ".35",
                  width: ".19",
                  height: ".5",
                  rx: ".02",
                  ry: ".07",
                  fill: "currentColor",
                },
              }),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "divider-2" }),
        _vm._v(" "),
        _c("div", { staticClass: "alert-box" }, [
          _c(
            "select",
            { class: _vm.IndicatorClasses, attrs: { name: "", id: "" } },
            [_c("option", { attrs: { value: "" } }, [_vm._v("Indicator")])]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "divider-2" }),
        _vm._v(" "),
        _c("div", { staticClass: "alert-box" }, [
          _c("div", { staticClass: "svg-container" }, [
            _c(
              "svg",
              {
                staticClass: "bell-1",
                attrs: {
                  width: "15",
                  height: "15",
                  viewBox: "0 0 15 15",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
                on: {
                  click: function ($event) {
                    return _vm.$bus.$emit("show_alert_box")
                  },
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M4.57731 2.47415C5.35245 1.53027 6.40377 1 7.5 1C8.59622 1 9.64753 1.53027 10.4227 2.47415C11.1978 3.41803 11.6333 4.6982 11.6333 6.03305C11.6333 6.81846 11.7398 7.55647 11.8999 8.31134C11.9447 8.48223 11.9942 8.64348 12.0473 8.79561C12.295 9.50511 12.977 9.74721 13.4859 10.2059C14.2455 10.8907 14.0986 12.3558 13.4668 12.9247C13.4668 12.9247 12.4467 14 7.5 14C2.55321 14 1.53314 12.9247 1.53314 12.9247C0.90135 12.3558 0.754497 10.8907 1.51407 10.2059C2.02295 9.74721 2.70493 9.50516 2.95269 8.79567C3.19965 8.08847 3.36669 7.1838 3.36669 6.03305C3.36669 4.6982 3.80216 3.41803 4.57731 2.47415Z",
                    stroke: "#9F9F9F",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                  },
                }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass: "bell-2",
              attrs: {
                width: "6",
                height: "3",
                viewBox: "0 0 6 3",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M1 1C1.38229 1.59513 2.13454 2 2.99999 2C3.86546 2 4.61769 1.59513 5 1",
                  stroke: "#9F9F9F",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              on: {
                click: function ($event) {
                  return _vm.$bus.$emit("show_alert_box")
                },
              },
            },
            [_c("p", { staticClass: "alert-1" }, [_vm._v("Alert")])]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "divider-2" }),
        _vm._v(" "),
        _c("div", { staticClass: "alert-box" }, [
          _c(
            "div",
            {
              staticClass: "replay",
              class: { disabled: _vm.replayActive },
              on: { click: _vm.replaySelected },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 28 28",
                    width: "28",
                    height: "28",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      fill: "none",
                      stroke: "currentColor",
                      d: "M13.5 20V9l-6 5.5 6 5.5zM21.5 20V9l-6 5.5 6 5.5z",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "alert" }, [_vm._v("Replay")]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "sign-save-box" }, [
        _c("div", { staticClass: "switch-home" }, [
          _c("label", { staticClass: "container" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.isDark,
                  expression: "isDark",
                },
              ],
              attrs: { checked: "checked", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.isDark)
                  ? _vm._i(_vm.isDark, null) > -1
                  : _vm.isDark,
              },
              on: {
                click: _vm.handleTheme,
                change: function ($event) {
                  var $$a = _vm.isDark,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.isDark = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.isDark = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.isDark = $$c
                  }
                },
              },
            }),
            _vm._v(" "),
            _c(
              "svg",
              {
                staticClass: "moon",
                attrs: {
                  viewBox: "0 0 384 512",
                  height: "1em",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M223.5 32C100 32 0 132.3 0 256S100 480 223.5 480c60.6 0 115.5-24.2 155.8-63.4c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6c-96.9 0-175.5-78.8-175.5-176c0-65.8 36-123.1 89.3-153.3c6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "svg",
              {
                staticClass: "sun",
                attrs: {
                  viewBox: "0 0 512 512",
                  height: "1em",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M361.5 1.2c5 2.1 8.6 6.6 9.6 11.9L391 121l107.9 19.8c5.3 1 9.8 4.6 11.9 9.6s1.5 10.7-1.6 15.2L446.9 256l62.3 90.3c3.1 4.5 3.7 10.2 1.6 15.2s-6.6 8.6-11.9 9.6L391 391 371.1 498.9c-1 5.3-4.6 9.8-9.6 11.9s-10.7 1.5-15.2-1.6L256 446.9l-90.3 62.3c-4.5 3.1-10.2 3.7-15.2 1.6s-8.6-6.6-9.6-11.9L121 391 13.1 371.1c-5.3-1-9.8-4.6-11.9-9.6s-1.5-10.7 1.6-15.2L65.1 256 2.8 165.7c-3.1-4.5-3.7-10.2-1.6-15.2s6.6-8.6 11.9-9.6L121 121 140.9 13.1c1-5.3 4.6-9.8 9.6-11.9s10.7-1.5 15.2 1.6L256 65.1 346.3 2.8c4.5-3.1 10.2-3.7 15.2-1.6zM160 256a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zm224 0a128 128 0 1 0 -256 0 128 128 0 1 0 256 0z",
                  },
                }),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "divider-3" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "fullscreen", on: { click: _vm.handleFullScreen } },
          [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 28 28",
                  width: "25",
                  height: "25",
                },
              },
              [
                _c("path", {
                  attrs: {
                    fill: "currentColor",
                    d: "M8.5 6A2.5 2.5 0 0 0 6 8.5V11h1V8.5C7 7.67 7.67 7 8.5 7H11V6H8.5zM6 17v2.5A2.5 2.5 0 0 0 8.5 22H11v-1H8.5A1.5 1.5 0 0 1 7 19.5V17H6zM19.5 7H17V6h2.5A2.5 2.5 0 0 1 22 8.5V11h-1V8.5c0-.83-.67-1.5-1.5-1.5zM22 19.5V17h-1v2.5c0 .83-.67 1.5-1.5 1.5H17v1h2.5a2.5 2.5 0 0 0 2.5-2.5z",
                  },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "divider-3" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "save", on: { mouseover: _vm.handleMouseOver } },
          [
            _c(
              "svg",
              {
                staticClass: "save-svg",
                attrs: {
                  width: "11",
                  height: "13",
                  viewBox: "0 0 11 13",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M9.83289 11.5223C9.80193 11.9545 9.28191 12.1565 8.96653 11.8589L6.13873 9.18983C5.78031 8.85153 5.21968 8.85153 4.86126 9.18982L2.03348 11.8589C1.71813 12.1565 1.19805 11.9545 1.16714 11.5223C0.957047 8.58578 0.948186 5.64088 1.1227 2.70392C1.17999 1.74004 1.9884 1 2.95552 1H8.04447C9.01162 1 9.81997 1.74004 9.87724 2.70392C10.0519 5.64088 10.0429 8.58578 9.83289 11.5223Z",
                    stroke: "#939393",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "save-text" }, [_vm._v("Save")]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "divider-3" }),
        _vm._v(" "),
        _c("div", { staticClass: "toggelRightToolBar" }, [
          _c("div", { staticClass: "toggle-container" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.isChecked,
                  expression: "isChecked",
                },
              ],
              staticClass: "toggle-input",
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.isChecked)
                  ? _vm._i(_vm.isChecked, null) > -1
                  : _vm.isChecked,
              },
              on: {
                click: _vm.handleRightBarDisplay,
                change: function ($event) {
                  var $$a = _vm.isChecked,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.isChecked = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.isChecked = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.isChecked = $$c
                  }
                },
              },
            }),
            _vm._v(" "),
            _c(
              "svg",
              {
                staticClass: "toggle",
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 292 142",
                },
              },
              [
                _c("path", {
                  staticClass: "toggle-background",
                  attrs: {
                    d: "M71 142C31.7878 142 0 110.212 0 71C0 31.7878 31.7878 0 71 0C110.212 0 119 30 146 30C173 30 182 0 221 0C260 0 292 31.7878 292 71C292 110.212 260.212 142 221 142C181.788 142 173 112 146 112C119 112 110.212 142 71 142Z",
                  },
                }),
                _vm._v(" "),
                _c("rect", {
                  staticClass: "toggle-icon on",
                  attrs: {
                    rx: "6",
                    height: "64",
                    width: "12",
                    y: "39",
                    x: "64",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "toggle-icon off",
                  attrs: {
                    d: "M221 91C232.046 91 241 82.0457 241 71C241 59.9543 232.046 51 221 51C209.954 51 201 59.9543 201 71C201 82.0457 209.954 91 221 91ZM221 103C238.673 103 253 88.6731 253 71C253 53.3269 238.673 39 221 39C203.327 39 189 53.3269 189 71C189 88.6731 203.327 103 221 103Z",
                    "fill-rule": "evenodd",
                  },
                }),
                _vm._v(" "),
                _c("g", { attrs: { filter: "url('#goo')" } }, [
                  _c("rect", {
                    staticClass: "toggle-circle-center",
                    attrs: {
                      fill: "#fff",
                      rx: "29",
                      height: "58",
                      width: "116",
                      y: "42",
                      x: "13",
                    },
                  }),
                  _vm._v(" "),
                  _c("rect", {
                    staticClass: "toggle-circle left",
                    attrs: {
                      fill: "#fff",
                      rx: "58",
                      height: "114",
                      width: "114",
                      y: "14",
                      x: "14",
                    },
                  }),
                  _vm._v(" "),
                  _c("rect", {
                    staticClass: "toggle-circle right",
                    attrs: {
                      fill: "#fff",
                      rx: "58",
                      height: "114",
                      width: "114",
                      y: "14",
                      x: "164",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "filter",
                  { attrs: { id: "goo" } },
                  [
                    _c("feGaussianBlur", {
                      attrs: {
                        stdDeviation: "10",
                        result: "blur",
                        in: "SourceGraphic",
                      },
                    }),
                    _vm._v(" "),
                    _c("feColorMatrix", {
                      attrs: {
                        result: "goo",
                        values: "1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7",
                        mode: "matrix",
                        in: "blur",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }