import Vue from "vue";
import Router from "vue-router";

// Import your components
import home from "../../home.vue";
import SignUpEmail from "../auth/SignUpEmail.vue";
import SignInEmail from "../auth/SignInEmail.vue";
import VerifyEmail from "../auth/VerifyEmail.vue";
import UserInfo from "../auth/UserInfo.vue";
import OtpVerification from "../auth/OtpVerification.vue";
import Fail from "../auth/Fail.vue";
import CopiedLink from "../CopiedLink.vue";
Vue.use(Router);

export default new Router({
  mode: "history",
  base: "/",
  routes: [
    { path: "/", component: home },
    { path: "/signup", component: SignUpEmail },
    { path: "/signin", component: SignInEmail },
    { path: "/verify", component: VerifyEmail },
    { path: "/info", component: UserInfo },
    { path: "/otp", component: OtpVerification },
    { path: "/fail", component: Fail },
    { path: "/:id", component: CopiedLink, name: CopiedLink, props: true },
  ],
});
