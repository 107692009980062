<template>
  <div>
    <!-- Account Settings Page -->
    <AccountSettingsPage v-show="accountSettingsPage"></AccountSettingsPage>

    <!-- Main component  -->
    <div
      :id="id"
      ref="tradingVueRef"
      class="trading-vue"
      :style="{
        color: chart_props.colors.text,
        font: font_comp,
        width: width + 'px',
        height: height + 'px',
      }"
      @mousedown="mousedown"
      @mouseleave="mouseleave"
      @click="handleClick"
    >
      <Header
        :charts-tf="chartsTf"
        :replay-selected="replaySelected"
        :replay-active="replayActive"
        :TFfunction="handleTFfunction"
        @passShowSreenShot="passShowSreenShot"
        @fullscreen="fullscreen"
      />
      <toolbar
        v-if="toolbar"
        ref="toolbar"
        v-bind="chart_props"
        :config="chart_config"
        @custom-event="custom_event"
      />
      <widgets
        v-if="controllers.length"
        ref="widgets"
        :map="ws"
        :width="width"
        :height="height"
        :tv="this"
        :dc="data"
      />
      <chart
        :key="reset"
        ref="chart"
        :replay-selected="replaySelected"
        :replay-stopped="replayStopped"
        v-bind="chart_props"
        :tv_id="id"
        :config="chart_config"
        @custom-event="custom_event"
        @range-changed="range_changed"
        :set-replay-active="setReplayActive"
        @legend-button-click="legend_button"
        :tradingVueRef="this.$refs.tradingVueRef"
      />
      <transition name="tvjs-drift">
        <the-tip v-if="tip" :data="tip" @remove-me="tip = null" />
      </transition>

      <RightToolBar />

      <!-- for symbol search -->
      <SymbolSearch />
      <alert />

      <div v-show="JoinUsPopup">
        <!-- <JoinUsPopup /> -->
      </div>

      <!-- for watchlist -->
      <div v-show="getisWatchlistOpen">
        <WatchlistPopup />
      </div>
    </div>
  </div>
</template>

<script>
import Const from "./stuff/constants.js";
import Chart from "./components/Chart.vue";
import Toolbar from "./components/Toolbar.vue";
import Widgets from "./components/Widgets.vue";
import TheTip from "./components/TheTip.vue";
import XControl from "./mixins/xcontrol.js";
import Header from "./components/Header.vue";
import RightToolBar from "./components/RightToolBar.vue";
import SymbolSearch from "./components/SymbolSearch.vue";
import Alert from "./components/Alert.vue";
import AccountSettingsPage from "./components/AccountSettingsPage.vue";
import JoinUsPopup from "./components/JoinUsPopup.vue";
import WatchlistPopup from "./components/WatchlistPopup.vue";

export default {
  name: "TradingVue",
  components: {
    Chart,
    Toolbar,
    Widgets,
    TheTip,
    Header,
    RightToolBar,
    SymbolSearch,
    Alert,
    AccountSettingsPage,
    JoinUsPopup,
    WatchlistPopup,
  },
  mixins: [XControl],
  props: {
    TFfunction: {
      type: Function,
    },
    titleTxt: {
      type: String,
      default: "TradingVue.js",
    },
    id: {
      type: String,
      default: "trading-vue-js",
    },
    width: {
      type: Number,
      default: 800,
    },
    height: {
      type: Number,
      default: 421,
    },
    colorTitle: {
      type: String,
      default: "#42b883",
    },
    colorBack: {
      type: String,
      default: "#121826",
    },
    colorGrid: {
      type: String,
      default: "#2f3240",
    },
    colorText: {
      type: String,
      default: "#dedddd",
    },
    colorTextHL: {
      type: String,
      default: "#fff",
    },
    colorScale: {
      type: String,
      default: "#838383",
    },
    colorCross: {
      type: String,
      default: "#8091a0",
    },
    colorCandleUp: {
      type: String,
      default: "#23a776",
    },
    colorCandleDw: {
      type: String,
      default: "#e54150",
    },
    colorWickUp: {
      type: String,
      default: "#23a77688",
    },
    colorWickDw: {
      type: String,
      default: "#e5415088",
    },
    colorWickSm: {
      type: String,
      default: "transparent", // deprecated
    },
    colorVolUp: {
      type: String,
      default: "#79999e42",
    },
    colorVolDw: {
      type: String,
      default: "#ef535042",
    },
    colorPanel: {
      type: String,
      default: "#565c68",
    },
    colorTbBack: {
      type: String,
    },
    colorTbBorder: {
      type: String,
      default: "#8282827d",
    },
    colors: {
      type: Object,
    },
    font: {
      type: String,
      default: Const.ChartConfig.FONT,
    },
    toolbar: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object,
      required: true,
    },
    // Your overlay classes here
    overlays: {
      type: Array,
      default: function () {
        return [];
      },
    },
    // Overwrites ChartConfig values,
    // see constants.js
    chartConfig: {
      type: Object,
      default: function () {
        return {};
      },
    },
    legendButtons: {
      type: Array,
      default: function () {
        return [];
      },
    },
    indexBased: {
      type: Boolean,
      default: false,
    },
    extensions: {
      type: Array,
      default: function () {
        return [];
      },
    },
    xSettings: {
      type: Object,
      default: function () {
        return {};
      },
    },
    skin: {
      type: String, // Skin Name
    },
    timezone: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    // Copy a subset of TradingVue props
    chart_props() {
      let offset = this.$props.toolbar ? this.chart_config.TOOLBAR : 0;
      let chart_props = {
        replay: this.$props.data.replay, // for replay active/inactive
        title_txt: this.$props.titleTxt,
        overlays: this.$props.overlays.concat(this.mod_ovs),
        data: this.decubed,
        width: this.$props.width - offset - this.getWidth,
        height: this.$props.height,
        font: this.font_comp,
        buttons: this.$props.legendButtons,
        toolbar: this.$props.toolbar,
        ib: this.$props.indexBased || this.index_based || false,
        colors: Object.assign({}, this.$props.colors || this.colorpack),
        skin: this.skin_proto,
        timezone: this.$props.timezone,
      };

      this.parse_colors(chart_props.colors);
      return chart_props;
    },
    getWidth() {
      if (this.getShowBar) return this.$store.getters.rightToolBarWidth;
      else {
        return 0;
      }
    },
    getShowBar() {
      return this.$store.getters.getShowBar;
    },
    chart_config() {
      return Object.assign({}, Const.ChartConfig, this.$props.chartConfig);
    },
    decubed() {
      let data = this.$props.data;
      if (data.data !== undefined) {
        // DataCube detected
        data.init_tvjs(this);
        return data.data;
      } else {
        return data;
      }
    },
    index_based() {
      const base = this.$props.data;
      if (base.chart) {
        return base.chart.indexBased;
      } else if (base.data) {
        return base.data.chart.indexBased;
      }
      return false;
    },
    mod_ovs() {
      let arr = [];
      for (var x of this.$props.extensions) {
        arr.push(...Object.values(x.overlays));
      }
      return arr;
    },
    font_comp() {
      return this.skin_proto && this.skin_proto.font
        ? this.skin_proto.font
        : this.font;
    },
    getIsAccountSettingsPageOpen() {
      return this.$store.getters.getIsAccountSettingsPageOpen;
    },
    getJoinUsPopup() {
      return this.$store.getters.getJoinUsPopup;
    },
    getisWatchlistOpen() {
      return this.$store.getters.getisWatchlistOpen;
    },
  },
  data() {
    return {
      reset: 0,
      tip: null,
      replayActive: false,
      accountSettingsPage:
        JSON.parse(localStorage.getItem("isAccountSettingsPageOpen")) ||
        this.getIsAccountSettingsPageOpen,
      JoinUsPopup: false,
      fullScreen: false,
      openWatchlistPopup: false,
    };
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeyDown);
    this.JoinUsPopup = this.getShowPopup;
    this.handleCandleColor();
    this.handleWickColor();
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeyDown);

    this.custom_event({ event: "before-destroy" });
    this.ctrl_destroy();
  },
  methods: {
    handleClick() {
      this.$bus.$emit("removeScreenShotComponent");
    },
    handleCandleColor() {
      this.$bus.$on("candlecolors", (candleColors) => {
        console.log(candleColors);

        this.$props.colorCandleUp = candleColors.colorUp;
        this.$props.colorCandleDw = candleColors.colorDown;
      });
    },
    handleWickColor() {
      this.$bus.$on("wickcolors", (wickColors) => {
        this.$props.colorWickUp = wickColors.colorUp;
        this.$props.colorWickDw = wickColors.colorDown;
      });
    },
    passShowSreenShot(showHoverComponent) {
      this.showHoverComponent = showHoverComponent;
    },
    setReplayActive(val) {
      this.replayActive = val;
    },
    fullscreen(fullscreen) {
      console.log(fullscreen);

      this.fullScreen = fullscreen;
      this.handleFullScreen();
    },
    handleFullScreen() {
      if (this.fullScreen) {
        // Request fullscreen for the trading-vue element
        if (this.$refs.tradingVueRef.requestFullscreen) {
          this.$refs.tradingVueRef.requestFullscreen();
        } else if (this.$refs.tradingVueRef.webkitRequestFullscreen) {
          // Safari
          this.$refs.tradingVueRef.webkitRequestFullscreen();
        } else if (this.$refs.tradingVueRef.msRequestFullscreen) {
          // IE11
          this.$refs.tradingVueRef.msRequestFullscreen();
        } else if (this.$refs.tradingVueRef.mozRequestFullScreen) {
          // Firefox
          this.$refs.tradingVueRef.mozRequestFullScreen();
        }
        this.$bus.$emit("handleFullScreen");
      } else {
        // Exit fullscreen
        if (document.fullscreenElement) {
          // Check if the document is currently in fullscreen mode
          if (document.exitFullscreen) {
            document.exitFullscreen().catch((err) => {
              console.error("Failed to exit fullscreen mode:", err);
            });
          } else if (document.webkitExitFullscreen) {
            // Safari
            document.webkitExitFullscreen();
          } else if (document.msExitFullscreen) {
            // IE11
            document.msExitFullscreen();
          } else if (document.mozCancelFullScreen) {
            // Firefox
            document.mozCancelFullScreen();
          }
          this.$bus.$emit("handleFullScreen");
        } else {
          console.warn("Document is not in fullscreen mode.");
        }
      }
    },
    replayStopped() {
      this.data.setReplay(true);
    },

    // Will work whenever replay option is selected
    replaySelected() {
      if (!JSON.parse(localStorage.getItem("isLogin"))) {
        this.$store.commit("updateJoinUsPopup", true);
        return;
      }
      this.data.setReplay(!this.data.replay);
    },

    // TODO: reset extensions?
    resetChart(resetRange = true) {
      this.reset++;
      let range = this.getRange();
      if (!resetRange && range[0] && range[1]) {
        this.$nextTick(() => this.setRange(...range));
      }
      this.$nextTick(() =>
        this.custom_event({
          event: "chart-reset",
          args: [],
        })
      );
    },
    goto(t) {
      // TODO: limit goto & setRange (out of data error)
      if (this.chart_props.ib) {
        const ti_map = this.$refs.chart.ti_map;
        t = ti_map.gt2i(t, this.$refs.chart.ohlcv);
      }
      this.$refs.chart.goto(t);
    },
    setRange(t1, t2) {
      if (this.chart_props.ib) {
        const ti_map = this.$refs.chart.ti_map;
        const ohlcv = this.$refs.chart.ohlcv;
        t1 = ti_map.gt2i(t1, ohlcv);
        t2 = ti_map.gt2i(t2, ohlcv);
      }
      this.$refs.chart.setRange(t1, t2);
    },
    getRange() {
      if (this.chart_props.ib) {
        const ti_map = this.$refs.chart.ti_map;
        // Time range => index range
        return this.$refs.chart.range.map((x) => ti_map.i2t(x));
      }
      return this.$refs.chart.range;
    },
    getCursor() {
      let cursor = this.$refs.chart.cursor;
      if (this.chart_props.ib) {
        const ti_map = this.$refs.chart.ti_map;
        let copy = Object.assign({}, cursor);
        copy.i = copy.t;
        copy.t = ti_map.i2t(copy.t);
        return copy;
      }
      return cursor;
    },
    showTheTip(text, color = "orange") {
      this.tip = { text, color };
    },
    legend_button(event) {
      this.custom_event({
        event: "legend-button-click",
        args: [event],
      });
    },
    custom_event(d) {
      if ("args" in d) {
        this.$emit(d.event, ...d.args);
      } else {
        this.$emit(d.event);
      }
      let data = this.$props.data;
      let ctrl = this.controllers.length !== 0;
      if (ctrl) this.pre_dc(d);
      if (data.tv) {
        // If the data object is DataCube
        data.on_custom_event(d.event, d.args);
      }
      if (ctrl) this.post_dc(d);
    },
    range_changed(r) {
      // if user is not login show popup
      if (!JSON.parse(localStorage.getItem("isLogin"))) {
        this.JoinUsPopup = true;
      }
      if (this.chart_props.ib) {
        const ti_map = this.$refs.chart.ti_map;
        r = r.map((x) => ti_map.i2t(x));
      }
      this.$emit("range-changed", r);
      this.custom_event({ event: "range-changed", args: [r] });
      if (this.onrange) this.onrange(r);
    },
    set_loader(dc) {
      this.onrange = (r) => {
        let pf = this.chart_props.ib ? "_ms" : "";
        let tf = this.$refs.chart["interval" + pf];
        dc.range_changed(r, tf);
      };
    },
    parse_colors(colors) {
      for (var k in this.$props) {
        if (k.indexOf("color") === 0 && k !== "colors") {
          let k2 = k.replace("color", "");
          k2 = k2[0].toLowerCase() + k2.slice(1);
          if (colors[k2]) continue;
          colors[k2] = this.$props[k];
        }
      }
    },
    mousedown() {
      this.$refs.chart.activated = true;
    },
    mouseleave() {
      this.$refs.chart.activated = false;
    },
    //this function gets value from header.vue and passes on to home.vue
    handleTFfunction(selectedTime) {
      this.TFfunction(selectedTime);
    },
  },
  watch: {
    getIsAccountSettingsPageOpen(newBool) {
      this.accountSettingsPage = newBool;
    },
    getJoinUsPopup(newBool) {
      this.JoinUsPopup = newBool;
    },
    getisWatchlistOpen(newBool) {
      this.isWatchlistOpen = newBool;
    },
  },
};
</script>
<style>
/* Anit-boostrap tactix */
.trading-vue *,
::after,
::before {
  box-sizing: content-box;
}
.trading-vue img {
  vertical-align: initial;
}
</style>
