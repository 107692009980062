<template>
    <div :class="pageClass">
        <div class="mainComponent">
            <!-- absolute component -->
            <p :class="companyNameClass">
                <img src="https://res.cloudinary.com/dzd8l9wym/image/upload/v1722250225/iaennuve3ukfiptsqr1d.png" alt="logo" class="logo">
                Tradilent
            </p>
            <p class="register">
                Have an account ?
                <router-link to="/signup" :class="signinClass">Sign Up</router-link>
            </p>
            <div class="contant">
                <p>Contact us</p>
            </div>

            <div :class="formFrameClass">
                <router-link to="/signin" :class="backClass">◀ <span class="backText">Go Back</span></router-link>
                <div class="form">
                    <h3 :class="h3Class">Enter One Time Code</h3>
                    <p :class="notifyClass">
                        Enter 6-digit code we’ve sent to
                        <span class="email">{{ email }}</span> to confirm your email address
                    </p>
                    <div class="opt">
                        <input type="text" v-for="(value, index) in otpArray" :key="index" v-model="otpArray[index]"
                            @input="onInput(index)" @keydown="onKeydown($event, index)" @paste="onPaste($event)"
                            maxlength="1" class="otp-input" :ref="`otp${index}`" />
                    </div>
                    <button @click="verifyOTP" class="signIn">Verify Signup Address</button>
                </div>
            </div>
            <p></p>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {SERVER_URL} from '../../constant.js'

export default {
    name: 'OtpVerification',
    data() {
        return {
            email: localStorage.getItem('email'),
            otpArray: Array(6).fill(''),
            isDark: JSON.parse(localStorage.getItem("themeIsDark")) || this.getTheme,
        };
    },
    computed:{
        getTheme() {
            return this.$store.getters.getTheme;
        },
        pageClass() {
            return this.isDark ? "page-dark" : "page";
        },
        companyNameClass() {
            return this.isDark ? "companyName-dark" : "companyName";
        },
        formFrameClass() {
            return this.isDark ? "formFrame-dark" : "formFrame";
        },
        notifyClass() {
            return this.isDark ? "notify-dark" : "notify";
        },
        signinClass() {
            return this.isDark ? "signin-dark" : "signin";
        },
        h3Class() {
            return this.isDark ? "h3-dark" : "h3";
        },
        backClass() {
            return this.isDark ? "back-dark" : "back";
        },
    },
    methods: {
        onInput(index) {
            if (this.otpArray[index].length === 1 && index < 5) {
                this.$refs[`otp${index + 1}`][0].focus(); // Move focus to the next input
            }
        },
        onKeydown(event, index) {
            if (event.key === 'Backspace' && this.otpArray[index] === '' && index > 0) {
                this.$refs[`otp${index - 1}`][0].focus(); // Move focus to the previous input
            }
        },
        onPaste(event) {
            const pasteData = event.clipboardData.getData('text');
            if (pasteData.length != 6) {
                this.$toast('Please paste a 6 digit code',{
                        // For the actual toast, including different toast types:
                        toastClassName: "my-custom-toast-class",

                        // For the toast body when using strings or a custom component as content
                        bodyClassName: ["custom-class-1", "custom-class-2"]
                    });
            }
            if (pasteData.length === 6 && /^[0-9]+$/.test(pasteData)) {
                this.otpArray = pasteData.split('');
                this.$nextTick(() => {
                    this.$refs.otp5[0].focus(); // Move focus to the last input
                });
            }
            event.preventDefault(); // Prevent the default paste action
        },
        async verifyOTP() {
            const otp = this.otpArray.join(''); // Combine the array into a single string
            console.log('Entered OTP:', otp);

            const url = `${SERVER_URL}/api/verifyEmail`;
            const data = {
                email: localStorage.getItem('email'),
                otp: otp,
            };
            try {
                const res = await axios.post(url, data);
                // console.log(res.data);
                this.$toast(res.data.message,{
                        // For the actual toast, including different toast types:
                        toastClassName: "my-custom-toast-class",

                        // For the toast body when using strings or a custom component as content
                        bodyClassName: ["custom-class-1", "custom-class-2"]
                    });
                localStorage.setItem('isLogin', true);
                if (res.data.redirect) {
                    this.$router.push(`${res.data.redirect}`);
                }
            } catch (err) {
                console.error(err.response.data);
                this.$toast(err.response.data,{
                        // For the actual toast, including different toast types:
                        toastClassName: "my-custom-toast-class",

                        // For the toast body when using strings or a custom component as content
                        bodyClassName: ["custom-class-1", "custom-class-2"]
                    });
                localStorage.setItem('isLogin', false);
            }
        },
    },
    watch:{
        getTheme(newTheme) {
            this.isDark = newTheme;
        },
    }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
    font-family: 'Montserrat', sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.page {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}
.page-dark {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
}

.mainComponent {
    width: 80%;
    height: 80%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.companyName {
    display: flex;
    align-items: center;
    position: absolute;
    top: -3rem;
    left: 0;
    color: #000;
    font-family: Montserrat;
    font-size: 1.26075rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.00475rem;
}
.companyName-dark{
    display: flex;
    align-items: center;
    position: absolute;
    top: -3rem;
    left: 0;
    color: #fff;
    font-family: Montserrat;
    font-size: 1.26075rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.00475rem;
}

.register {
    color: var(--Neutral-Var-40, #646866);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    position: absolute;
    top: -3rem;
    right: 0;
}

.signin {
    color: var(--secondary-60, #EE9644);
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
}
.signin-dark {
    background-image: linear-gradient(180deg,#fff 14.17%,#b372cf);
    -webkit-background-clip: text; /* For WebKit browsers */
    background-clip: text; /* For other browsers */
    color: transparent; 
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    text-decoration: underline;
    text-decoration-color: #b372cf;
}

.contant {
    color: var(--Neutral-30, #4C4C4C);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    position: absolute;
    bottom: -3rem;
    left: 0;
}

.formFrame {
    height: 80%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 1rem;
    box-shadow: 0px -15px 100px 15px rgba(7, 24, 20, 0.08);
    padding-bottom: 20px;
    background-color: #fbfefd;
    position: relative;
}
.formFrame-dark {
    height: 80%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 1rem;
    box-shadow: 0px -15px 100px 15px #602A9A;
    padding-bottom: 20px;
    background-color: #18162099;
    position: relative;
}

.back {
    position: absolute;
    top: 5px;
    left: 5px;
    font-size: small;
}
.back-dark {
    position: absolute;
    top: 5px;
    left: 5px;
    font-size: small;
    color: white;
    
}


.backText {
    text-decoration: underline;
}

.form {
    height: fit-content;
    width: calc(100% - 10rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.h3 {
    color: var(--Black6, #071814);
    font-family: Montserrat;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
}
.h3-dark {
    color: var(--Black6, #fff);
    font-family: Montserrat;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
}

.notify {
    margin-top: -20px;
    color: var(--neutal20, #333);
    text-align: center;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}
.notify-dark{
    margin-top: -20px;
    color: var(--Black6, #fff);
    text-align: center;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.email {
    color: var(--neutal20, #333);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
}

.otp-input {
    margin-top: 20px;
    width: 2rem;
    height: 2rem;
    text-align: center;
    font-size: 1.5rem;
    margin: 0.2rem;
}

.signIn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    padding: 0.8125rem 7.75rem;
    border-radius: 0.5rem;
    text-wrap: nowrap;
    text-align: center;
    border: 1px solid var(--Neutral-Var-40, #646866);
    cursor: pointer;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    background-color: transparent;
    color: var(--Neutral-Var-40, #646866);
}

.signIn:hover {
    background-color: black;
    color: white;
}
.logo{
    height: 40px;
    width: 50px;
}
@media (max-width: 900px) {
    .formFrame {
        height: 80%;
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .formFrame-dark {
        height: 80%;
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 500px) {
    .formFrame {
        height: 80%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .formFrame-dark {
        height: 80%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .form {
        height: 100%;
        width: 100%;
    }

    .mainComponent {
        margin-left: 2px;
        margin-right: 2px;
        width: 90%;
        height: fit-content;
        position: relative;
        display: flex;
        justify-content: center;
    }
    .companyName{
        font-size: 1rem;
        flex-direction: column;
        top: -5rem;
    }
    .companyName-dark{
        font-size: 1rem;
        flex-direction: column;
        top: -5rem;
    }
    .register{
        font-size: 1rem;
    }
    .contant {
        font-size: 1rem;
    }
}
</style>
