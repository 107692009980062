<script>
// The Fibonacci tool for drawing Fibonacci retracement levels on a chart

import Overlay from "../../mixins/overlay.js";
import Tool from "../../mixins/tool.js";
import Icons from "../../stuff/icons.json";
import Pin from "../primitives/pin.js";
import Seg from "../primitives/seg.js";

export default {
  name: "FiboTool", // Name of the component
  mixins: [Overlay, Tool], // Mixin dependencies
  // props: ["newIsLocked"],
  // watch: {
  //   newIsLocked() {
  //     this.isLocked = this.newIsLocked;
  //     this.isLockTool(this.isLocked);
  //   },
  // },
  methods: {
    // Meta information about the tool
    meta_info() {
      return { author: "chandan-atiuttam", version: "2.0.1" };
    },
    // Tool descriptor
    tool() {
      return {
        // Group under which the tool will be categorized
        group: "Fibonacci",
        // Icon representing the tool
        icon: Icons["fibretracement.svg"],
        // Type of the tool
        type: "Retracement",
        // Hint for the tool
        hint: "Fibonacci Retracement",
        // Default data for the tool
        data: [],
        // Default settings for the tool
        settings: {
          lineColor: "#000000",
          backgroundColors: [
            "#F5051D80",
            "#F5051D80",
            "#F0D32080",
            "#75F04A80",
            "#3EF07A80",
            "#4239F080",
            "#F5D98F80",
            "#A44DF580",
            "#F573B780",
            "#E794F580",
          ],
        },
        // Modifications for different modes of the tool
        mods: {
          ShiftMode: {
            settings: {
              price: false,
              time: true,
              shiftMode: true,
            },
            hidden: true,
          },
        },
      };
    },
    // Called after the overlay is mounted
    init() {
      // this.isLocked = this.newIsLocked;
      // this.isLockTool(this.isLocked);
      // First pin is placed at the mouse position
      this.pins.push(
        new Pin(this, "p1", {
          hidden: this.shift,
        })
      );
      // Second pin follows the mouse until it clicks
      this.pins.push(
        new Pin(this, "p2", {
          state: "tracking",
          hidden: this.shift,
        })
      );
      // if (this.newIsLocked) {
      //   this.pins[1].on("nodragging", () => {
      //     // Call when current tool drawing is finished
      //     // (Optionally) reset the mode back to 'Cursor'

      //     this.set_state("finished");
      //     this.$emit("drawing-mode-off");
      //     if (this.shift)
      //       this._$emit("custom-event", {
      //         event: "object-selected",
      //         args: [],
      //       });
      //   });
      // } else {
      this.pins[1].on("settled", () => {
        // Call when current tool drawing is finished
        // (Optionally) reset the mode back to 'Cursor'

        this.set_state("finished");
        this.$emit("drawing-mode-off");
        if (this.shift)
          this._$emit("custom-event", {
            event: "object-selected",
            args: [],
          });
      });
    },
    // Draw method for rendering the Fibonacci retracement levels
    draw(ctx) {
      if (!this.p1 || !this.p2) return;

      const layout = this.$props.layout;
      const xm = layout.t2screen((this.p1[0] + this.p2[0]) * 0.5);

      ctx.lineWidth = this.line_width;
      ctx.strokeStyle = this.line_color;

      let x1 = layout.t2screen(this.p1[0]);
      let y1 = layout.$2screen(this.p1[1]);
      let x2 = layout.t2screen(this.p2[0]);
      let y2 = layout.$2screen(this.p2[1]);

      let priceRange = Math.abs(this.p2[1] - this.p1[1]);
      let fibLevels = [
        0, 0.236, 0.382, 0.5, 0.618, 1, 1.618, 2.618, 3.618, 4.236,
      ];
      let fibPercentages = [
        "0.000%",
        "23.600%",
        "38.200%",
        "50.000%",
        "61.800%",
        "100.000%",
        "161.800%",
        "261.800%",
        "361.800%",
        "423.600%",
      ];
      let textColors = [
        "#C3B4B4",
        "#F71010",
        "#E3F952",
        "#3EDA4D",
        "#3EDA81",
        "#3390B7",
        "#978034",
        "#8D1DCE",
        "#E828EF",
        "#752777",
      ];

      let previousY = y1;
      fibLevels.forEach((level, index) => {
        // this will get the actual price from the price / side bar
        let fibPrice =
          this.p1[1] + level * priceRange * (this.p2[1] > this.p1[1] ? 1 : -1);
        let y = layout.$2screen(fibPrice);

        // Fill the background color between the previousY and the current y
        if (index < this.sett.backgroundColors.length) {
          this.horizontal(
            ctx,
            x1,
            previousY,
            x2,
            y,
            this.sett.backgroundColors[index]
          );
        }

        ctx.beginPath();
        ctx.moveTo(x1, y);
        ctx.lineTo(x2, y);
        ctx.stroke();

        // Draw the price value at the current Fibonacci level
        this.drawPriceValues(
          ctx,
          x1,
          y,
          fibPrice,
          fibPercentages[index],
          textColors[index]
        );

        previousY = y;
      });

      // Render pins
      this.render_pins(ctx);
    },

    // Draw horizontal lines and arrows
    horizontal(ctx, x1, yTop, x2, yBottom, backgroundColor) {
      const layout = this.$props.layout;
      const xdir = Math.sign(this.p2[0] - this.p1[0]);
      const ym =
        (layout.$2screen(this.p1[1]) + layout.$2screen(this.p2[1])) / 2;

      ctx.fillStyle = backgroundColor;
      ctx.fillRect(x1, yTop, x2 - x1, yBottom - yTop);

      ctx.beginPath();

      ctx.moveTo(x2 - 5 * xdir, ym - 4);
      ctx.lineTo(x2, ym);
      ctx.lineTo(x2 - 5 * xdir, ym + 4);
    },

    // this gives you the correct price values equally to the horizontal values
    drawPriceValues(ctx, x, y, price, percentage, textColor) {
      if (!this.p1 || !this.p2) return;

      // Example: Draw price at p1
      ctx.font = "12px Arial";
      ctx.fillStyle = textColor;
      ctx.textAlign = "left";
      ctx.fillText(`${percentage} ( ${price.toFixed(2)} )`, x + -70, y + -8);
    },

    // Define which tools this component should be used for
    use_for() {
      return ["FiboTool"];
    },

    // Define the colors for the data
    data_colors() {
      return [this.color];
    },
  },
  // Define internal settings and constants
  computed: {
    sett() {
      return this.$props.settings;
    },
    p1() {
      return this.$props.settings.p1;
    },
    p2() {
      return this.$props.settings.p2;
    },
    line_width() {
      return this.sett.lineWidth || 0.9;
    },
    line_color() {
      return this.sett.lineColor || "#000000"; // Default line color
    },
    color() {
      return this.sett.color || this.$props.colors.cross;
    },
    back_color() {
      return this.sett.backColor || "#9b9ba316";
    },
    value_back() {
      return this.sett.valueBack || "#9b9ba316";
    },
    value_color() {
      return this.sett.valueColor || this.$props.colors.text;
    },
    prec() {
      return this.sett.precision || 2;
    },
    new_font() {
      return "12px " + this.$props.font.split("px").pop();
    },
    price() {
      return "price" in this.sett ? this.sett.price : true;
    },
    time() {
      return "time" in this.sett ? this.sett.time : false;
    },
    shift() {
      return this.sett.shiftMode;
    },
  },
  data() {
    return {};
  },
};
</script>
